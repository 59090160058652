import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { Media } from "react-data-table-component";
import { FaUserEdit } from "react-icons/fa";
import { getAllProdutoData, verifyToken } from "../../sources/api";
import { AuthContext } from "../../contexts/auth";
import Table from "../../components/Table";
import "./style.css";

export const PesquisarProdutoPage = () => {
  const navigate = useNavigate();
  const { authenticated, logout } = useContext(AuthContext);

  const paginationComponentOptions = {
    rowsPerPageText: "Produtos por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.idProduto,
      hide: Media.MD,
    },
    {
      name: "Nome",
      selector: (row) => row.nomeProduto,
    },
    {
      name: "Categoria",
      selector: (row) => row.categoria,
      hide: Media.MD,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => navigate(`/editar-produto/${row.idProduto}`)}
          >
            <FaUserEdit />
          </button>
        </>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        (async () => {
          await getAllProdutoData().then(async (response) => {
            console.log(response);
            setData(response.data);
            setPending(false);
          });
        })();
      }
    })();
  }, []);

  return (
    <>
      <Layout>
        <div className="wrapper-table">
          <div className="table">
            <Table
              title="Pesquisar Produtos"
              data={data}
              columns={columns}
              pending={pending}
              paginationComponentOptions={paginationComponentOptions}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PesquisarProdutoPage;
