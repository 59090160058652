import { Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { createLote, getLotesFromProduto } from "../../sources/api";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { create } from "yup/lib/Reference";
import { HiCheck } from "react-icons/hi";

const FormLoteModal = ({
  showFormLote,
  setShowFormLote,
  idProduto,
  setLotes,
  lote,
  operation,
}) => {
  const [initialValues, setInitialValues] = useState({
    lote: {
      codigoNota: 0,
      quantidadeEstoque: 0,
      fk_idProduto: +idProduto,
    },
  });
  // const [originalValues, setOriginalValues] = useState(initialValues);
  const [typeOperation, setTypeOperation] = useState("");

  useEffect(() => {
    if (lote.idLote) {
      console.log("EDIT CARAI");
      setInitialValues({ lote });
      setTypeOperation("Edição");
    } else {
      setInitialValues({
        lote: {
          codigoNota: 0,
          quantidadeEstoque: 0,
          fk_idProduto: +idProduto,
        },
      });
      setTypeOperation("Criação");
      console.log("Novo porra");
    }
  }, [lote, idProduto]);

  const schema = yup
    .object()
    .shape({
      lote: yup.object().shape({
        codigoNota: yup
          .number()
          .positive("Código da nota deve ser positivo")
          .required("Código da nota é obrigatório"),
        quantidadeEstoque: yup
          .number()
          .positive("Quantidade deve ser um número positiva")
          .required("Quantidade necessária"),
        fk_idProduto: yup.number().required("ID do produto é requerido"),
      }),
    })
    .required();

  const handleSubmit = async (values) => {
    if (typeOperation === "Criação") {
      values.lote.tipoAlteracao = "Criação";
      values.lote.motivoAlteracao = "Entrada de produto";
    } else if (typeOperation === "Edição") {
      values.lote.tipoAlteracao = "Entrada/Ajuste";
      values.lote.motivoAlteracao = "Alteração Número Lote";
    }
    const user = localStorage.getItem("user");
    values.lote.fk_idUser = JSON.parse(user).idUser;

    await createLote(values.lote).then(async (response) => {
      if (response.status === 200) {
        await getLotesFromProduto(idProduto).then((response) => {
          if (response.status === 200) {
            setLotes(response.data);
          }
        });
        setShowFormLote(false);
      }
    });
  };

  return (
    <Modal
      show={showFormLote}
      onHide={() => setShowFormLote(false)}
      dialogClassName="modal-add-lote"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Adicionar Lote ao Produto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="validationFormik01">
                <Form.Label>Código da Nota</Form.Label>
                <Form.Control
                  type="number"
                  name="lote.codigoNota"
                  value={values.lote.codigoNota}
                  onChange={handleChange}
                  isValid={
                    touched?.lote?.codigoNota && !errors?.lote?.codigoNota
                  }
                  isInvalid={!!errors?.lote?.codigoNota}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.lote?.codigoNota}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationFormik02">
                <Form.Label>Quantidade em Estoque</Form.Label>
                <Form.Control
                  type="number"
                  name="lote.quantidadeEstoque"
                  value={values.lote.quantidadeEstoque}
                  onChange={handleChange}
                  isValid={
                    touched?.lote?.quantidadeEstoque &&
                    !errors?.lote?.quantidadeEstoque
                  }
                  isInvalid={!!errors?.lote?.quantidadeEstoque}
                  disabled={lote.idLote ? true : false}
                />
                {lote.idLote && (
                  <Form.Text className="text-muted">
                    Quantidade do lote só pode ser alterado na operação de
                    entrada/saída.
                  </Form.Text>
                )}

                <Form.Control.Feedback type="invalid">
                  {errors?.lote?.quantidadeEstoque}
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "flex-column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "1rem",
                  }}
                >
                  <Button type="submit" variant="success">
                    <HiCheck />
                    Enviar Dados
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowFormLote(false)}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default FormLoteModal;
