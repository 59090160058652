import axios from "axios";
import swal from "sweetalert";

export const api = axios.create({
  baseURL: "https://webserver.cp.pr.gov.br",
  withCredentials: true,
  headers: {
    common: {
      "Access-Control-Allow-Origin": "https://bancoracao.cp.pr.gov.br",
    },
  },
});

export const createSession = async (email, password) => {
  return api.post("/login", { email, password });
};

export const getTeste = async () => {
  return await api
    .get("/api/getTeste")
    .then((response) => {
      // console.log(response);
      return response;
    })
    .catch((err) => {
      console.log(err.response);
      return err.response;
    });
};

export const getAllPeopleData = async () => {
  return api.post("/getAllPeopleData");
};

export const getAllEmpresaData = async () => {
  return api.post("/getAllEmpresaData");
};

export const getAllProdutoData = async () => {
  return api.post("/getAllProdutoData");
};

export const createEmpresa = async (empresaData, contatos) => {
  return api.post("/cadastroEmpresa", { empresaData, contatos });
};

export const createProduto = async (produtoData) => {
  console.log(produtoData);
  return api.post("/cadastroProduto", { produtoData });
};

export const registraOperacao = async (data) => {
  return api.post("/registraOperacao", { data });
};

export const registraOperacaoEmpresa = async (data) => {
  return api.post("/registraOperacaoEmpresa", { data });
};

export const getProdutoDataById = async (idProduto) => {
  return api
    .post("/getProdutoDataById", { idProduto })
    .then((response) => {
      console.log(response);
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getEmpresaDataById = async (idEmpresa) => {
  return api
    .post("/getDadosEmpresaById", { idEmpresa })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const createPeople = async (userData, contatos) => {
  return api.post("/cadastroPessoa", { userData, contatos });
};

export const getPeopleDataById = async (idPeople) => {
  console.log("ID PEOPLE API:" + idPeople);
  return api
    .post("/getDadosPessoaById", { idPeople })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const deleteContatoPessoa = async (idContato) => {
  return api
    .post("/deleteContatoPessoa", { idContato })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const deleteContatoEmpresa = async (idContatoEmpresa) => {
  return api
    .post("/deleteContatoEmpresa", { idContatoEmpresa })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const verifyToken = async () => {
  return await api
    .post("/api/validateToken")
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log("Error: " + JSON.stringify(err.response.status));
      return err.response.status;
    });
};

export const getAllOperacaoPeople = async () => {
  return api.post("/getAllOperacaoPeople");
};

export const getAllOperacaoEmpresa = async () => {
  return api.post("/getAllOperacaoEmpresa");
};

export const getLotesFromProduto = async (idProduto) => {
  return api
    .post("/getLotesFromProduto", { idProduto })
    .then((data) => {
      swal("Sucesso", "Lotes carregados com sucesso!", "success");
      return data;
    })
    .catch((e) => {
      swal("Erro", "Erro ao carregar lotes", "error");
    });
};

export const getProdutoDataByIdTeste = async (idProduto) => {
  swal("Carregando", "Carregando produto", "info");
  return api
    .post("/getProdutoDataById", { idProduto })
    .then((response) => {
      swal("Sucesso", "Produto carregado com sucesso!", "success");
      console.log(response);
      return response;
    })
    .catch((err) => {
      console.log(err);
      swal("Erro", "Erro ao carregar produto", "error");
      return err;
    });
};

export const createLote = (lote) => {
  swal("Aguarde", "Enviando informações", "info");
  return api
    .post("/createLote", { lote })
    .then((response) => {
      swal("Sucesso!", "Lote criado com sucesso!", "success");
      return response;
    })
    .catch((err) => {
      console.log(err);
      swal("error", "Erro ao criar lote", "error");
      return err;
    });
};

export const updateLote = (lote) => {
  swal("Aguarde", "Enviando informações para o servidor", "info");
  return api
    .post("/updateLote", { lote })
    .then((response) => {
      swal("Sucesso!", "Lote criado com sucesso!", "success");
      return response;
    })
    .catch((err) => {
      console.log(err);
      swal("Erro", "Erro ao criar lote", "error");
      return err;
    });
};

export const getEvolucaoLote = (idLote) => {
  swal("Aguarde", "Carregando informações...", "info");
  return api
    .post("/getEvolucaoLote", { idLote })
    .then((response) => {
      swal("Sucesso!", "Relatório carregado com sucesso!", "success");
      return response;
    })
    .catch((err) => {
      console.log(err);
      swal("Erro", "Erro ao carregar relatório", "error");
      return err;
    });
};
