import React, { useEffect, useState, useId } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import InputMask from "react-input-mask";
import { useFieldArray, Controller } from "react-hook-form";

import "./style.css";

function ContactForm({
  control,
  register,
  setValue,
  getValue,
  errors,
  peopleData,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contatos",
  });

  const { flag, setFlag } = useState(0);

  useEffect(() => {
    // if (flag === 0) {
    peopleData?.contatos.forEach((contact) => {
      // for (const key in contact) {
      //   console.log(`KEY: ${key}: VALUE: ${contact[key]}`);
      // }
      append(contact);
    });
  }, []);
  // console.log("Contacts Page: " + peopleData.contacts);

  // if (contacts) {
  //   contacts.forEach((contact) => {
  //     append({ contact });
  //   });
  // }

  const appendContact = () => {
    console.log("appended");
    append({
      contato_telefone: "",
      contato_tipo: "Não informado",
      contato_nome: "",
      contato_vinculo: "",
    });
    console.log(fields);
  };

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <Row className="justify-content-center" key={item.id}>
            <Col xs="md">
              <Form.Group controlId="contato_telefone">
                <Form.Label>Telefone</Form.Label>
                {/* // TRY CHANGE ALL InputMASK */}
                <Controller
                  control={control}
                  name={`contatos.${index}.contato_telefone`}
                  render={({ field: { onChange, value } }) => (
                    <InputMask
                      className="form-control form-control-sm"
                      // name={`contatos.${index}.contato_telefone`}
                      type="text"
                      size="sm"
                      value={value}
                      onChange={onChange}
                      mask={"(99) 99999-9999"}
                      // maskChar={""}
                      {...register(`contatos.${index}.contato_telefone`, {
                        required: true,
                      })}
                    >
                      {(inputProps) => (
                        <input {...inputProps} className="input" />
                      )}
                    </InputMask>
                  )}
                />
                {errors?.contatos?.[index]?.contato_telefone && (
                  <span className="error-block">
                    {errors?.contatos?.[index]?.contato_telefone?.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xs="md">
              <Form.Group controlId="contato_tipo">
                <Form.Label>Tipo</Form.Label>
                <Form.Select
                  size="sm"
                  className="form-control form-control-sm"
                  name={`contatos.${index}.contato_tipo`}
                  {...register(`contatos.${index}.contato_tipo`, {
                    required: true,
                  })}
                >
                  <option>Não informado</option>
                  <option>Residencial</option>
                  <option>Comercial</option>
                  <option>Celular</option>
                </Form.Select>
                {errors?.contatos?.[index]?.contato_tipo && (
                  <span className="error-block">
                    {errors?.contatos?.[index]?.contato_tipo?.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xs="md">
              <Form.Group controlId="contato_nome">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  size="sm"
                  className="form-control form-control-sm"
                  name={`contatos.${index}.contato_nome`}
                  {...register(`contatos.${index}.contato_nome`, {
                    required: true,
                  })}
                  type="text"
                />
                {errors?.contatos?.[index]?.contato_nome && (
                  <span className="error-block">
                    {errors?.contatos?.[index]?.contato_nome?.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xs="md">
              <Form.Group controlId="contato_vinculo">
                <Form.Label>Vinculo</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  className="form-control form-control-sm"
                  name={`contatos.${index}.contato_vinculo`}
                  {...register(`contatos.${index}.contato_vinculo`, {
                    required: true,
                  })}
                />
                {errors?.contatos?.[index]?.contato_vinculo && (
                  <span className="error-block">
                    {errors?.contatos?.[index]?.contato_vinculo?.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xs="md" className="align-self-end justify-content-around">
              <Button
                className="btn btn-sm"
                variant="danger"
                onClick={() => remove(index)}
              >
                Apagar
              </Button>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col
          xs="md"
          className="button-wrapper auto justify-content-end"
          id="buttonAppendContato"
        >
          <button
            className="btn btn-sm btn-warning"
            onClick={appendContact}
            type="button"
          >
            Adicionar contato
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default ContactForm;
