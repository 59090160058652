import { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import PeoplePage from "./pages/PeoplePage";
import EmpresaPage from "./pages/EmpresaPage";
import PesquisarPessoaPage from "./pages/PesquisarPessoaPage";
import PesquisarEmpresaPage from "./pages/PesquisarEmpresaPage";
import ProdutoPage from "./pages/ProdutoPage";
import PesquisarProdutoPage from "./pages/PesquisarProdutoPage";
import SaidaProdutoPeoplePage from "./pages/SaidaProdutoPeoplePage";
import SaidaProdutoEmpresaPage from "./pages/SaidaProdutoEmpresaPage";
import LogOperacoesPessoaPage from "./pages/LogOperacoesPessoaPage";
import LogOperacoesEmpresaPage from "./pages/LogOperacoesEmpresaPage";
import EntradaDeProdutoPage from "./pages/EntradaDeProdutoPage";
import EvolucaoEstoquePage from "./pages/EvolucaoEstoquePage/EvolucaoEstoquePage";
import { AuthProvider, AuthContext } from "./contexts/auth";

const AppRoutes = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }
    if (!authenticated) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route
            exact
            path="/"
            element={
              <Private>
                <HomePage />
              </Private>
            }
          />
          <Route
            exact
            path="/cadastrar-pessoa"
            element={
              <Private>
                <PeoplePage />
              </Private>
            }
          />
          <Route
            exact
            path="/editar-pessoa/:id"
            element={
              <Private>
                <PeoplePage />
              </Private>
            }
          />
          <Route
            exact
            path="/cadastrar-empresa"
            element={
              <Private>
                <EmpresaPage />
              </Private>
            }
          />
          <Route
            exact
            path="/editar-empresa/:id"
            element={
              <Private>
                <EmpresaPage />
              </Private>
            }
          />
          <Route
            exact
            path="/pesquisar-pessoa"
            element={
              <Private>
                <PesquisarPessoaPage />
              </Private>
            }
          />
          <Route
            exact
            path="/pesquisar-empresa"
            element={
              <Private>
                <PesquisarEmpresaPage />
              </Private>
            }
          />
          <Route
            exact
            path="/cadastrar-produto"
            element={
              <Private>
                <ProdutoPage />
              </Private>
            }
          />
          <Route
            exact
            path="/editar-produto/:id"
            element={
              <Private>
                <ProdutoPage />
              </Private>
            }
          />
          <Route
            exact
            path="/pesquisar-produto"
            element={
              <Private>
                <PesquisarProdutoPage />
              </Private>
            }
          />
          <Route
            exact
            path="/saida-produto-pessoa"
            element={
              <Private>
                <SaidaProdutoPeoplePage />
              </Private>
            }
          />
          <Route
            exact
            path="/saida-produto-empresa"
            element={
              <Private>
                <SaidaProdutoEmpresaPage />
              </Private>
            }
          />
          <Route
            exact
            path="/entrada-de-produto"
            element={
              <Private>
                <EntradaDeProdutoPage />
              </Private>
            }
          />
          <Route
            exact
            path="/operacoes-pessoa"
            element={
              <Private>
                <LogOperacoesPessoaPage />
              </Private>
            }
          />
          <Route
            exact
            path="/operacoes-empresa"
            element={
              <Private>
                <LogOperacoesEmpresaPage />
              </Private>
            }
          />
          <Route
            exact
            path="evolucao-estoque"
            element={
              <Private>
                <EvolucaoEstoquePage />
              </Private>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
