import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { verifyToken, getTeste } from "../../sources/api";
import Layout from "../../components/Layout";
import { ComprovantePessoa } from "../../components/PdfGenerator";
import "./styles.css";

const HomePage = () => {
  const { authenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [testes, setTestes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        setLoading(false);
        logout();
        navigate("/login");
      }
    })();
  }, []);

  const handleLogout = () => {
    logout();
  };

  const handleGenerateRecibo = () => {
    const data = {
      people: { nome: "Nasser Ruiz Rehman" },
      produto: [
        {
          nomeProduto: "Teste Produto 1 20KG",
          categoria: "Cães",
          quantidade_saida: 20,
        },
        {
          nomeProduto: "Teste Produto n2 12 Kilos",
          categoria: "Gatos",
          quantidade_saida: 30,
        },
      ],
    };
    ComprovantePessoa(data);
  };

  return (
    <>
      <Layout></Layout>
    </>
  );
};

export default HomePage;
