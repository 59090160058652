import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  useContext,
} from "react";
import Layout from "../../components/Layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { FaSearch } from "react-icons/fa";
import "./style.css";
import { Formik, FieldArray, ErrorMessage, yupToFormErrors } from "formik";
import * as yup from "yup";
import SearchEmpresaModal from "../../components/SearchEmpresaModal";
import SearchProdutoModal from "../../components/SearchProdutoModal";
import {
  getAllEmpresaData,
  getAllProdutoData,
  verifyToken,
  registraOperacaoEmpresa,
} from "../../sources/api";
import { SwalOpen } from "../../components/AlertModal";
import { AuthContext } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Media } from "react-data-table-component";
import { ComprovanteEmpresa } from "../../components/PdfGenerator";
import swal from "@sweetalert/with-react";

export const SaidaProdutoEmpresaPage = () => {
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const { authenticated, logout } = useContext(AuthContext);
  const [empresaData, setEmpresaData] = useState();
  const [produtoData, setProdutoData] = useState();
  const [showModalSearchEmpresa, setShowModalSearchEmpresa] = useState(false);
  const [showModalSearchProduto, setShowModalSearchProduto] = useState(false);
  const removeRef = useRef();
  const pushRef = useRef();
  const formRef = useRef();

  const schema = yup
    .object()
    .shape({
      empresa: yup.object().shape({
        idEmpresa: yup.number().positive().integer().min(1).required(),
        nomeEmpresa: yup
          .string()
          .required("É necessário selecionar uma empresa/ONG"),
      }),
      saida_produto_empresa: yup.array().of(
        yup.object().shape({
          idProduto: yup
            .number("Precisa ser um número")
            .positive("Precisa ser positivo")
            .integer("Inteiro")
            .min(1, "Pelo menos 1")
            .required(),
          nomeProduto: yup
            .string("Precisa ser uma string")
            .required("Produto é requerido"),
          categoria: yup
            .string("Deve ser um texto")
            .required("Categoria é obrigatório"),
          quantidade_saida: yup
            .number("Precisa ser um número")
            .positive("Número positivo")
            .integer("Número inteiro")
            .min(1, "Precisa ser maior que 0 ou 1")
            .required("Uma quantidade é necessária")
            .when(
              "quantidadeEstoque",
              (quantidadeEstoque, field) =>
                quantidadeEstoque &&
                field.max(
                  quantidadeEstoque,
                  `Quantidade não pode ser maior que ${quantidadeEstoque}`
                )
            ),
          quantidadeEstoque: yup
            .number("Precisa ser um número")
            .positive("Número positivo")
            .integer("Número inteiro")
            .min(1, "Precisa ser maior que 0 ou 1")
            .required("Uma quantidade é necessária"),
        })
      ),
    })
    .required();

  // TODO GET all data from people and empresa pass for props to modal
  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        (async () => {
          SwalOpen({
            status: true,
            header: "Buscando dados...",
            message: "Aguarde",
            allowOutsideClick: false,
            timer: 3000,
            type: "info",
          });
          await getAllEmpresaData().then(async (response) => {
            setEmpresaData(response.data);
          });
          await getAllProdutoData()
            .then(async (response) => {
              setProdutoData(response.data);
              console.log(produtoData);
            })
            .then(() => {
              SwalOpen({
                status: true,
                header: "Busca concluida",
                message: "Esta mensagem desaparecerá",
                type: "success",
                timer: 3000,
              });
            });
        })();
      }
    })();
    console.log(produtoData);
  }, [reducerValue]);

  const onSelectProdutoHandler = async (data) => {
    console.log(formRef.current.values);
    const validator =
      (await formRef.current.values?.saida_produto_empresa.filter((element) => {
        console.log("LOTES" + element.idLote, data.idLote);
        return element.idLote === data.idLote;
      })) || [];
    console.log(validator);
    if (validator.length === 0) {
      pushRef.current({
        idProduto: data.idProduto,
        nomeProduto: data.nomeProduto,
        categoria: data.categoria,
        idLote: data.idLote,
        codigoNota: data.codigoNota,
        quantidadeEstoque: data.quantidadeEstoque,
        quantidade_saida: 0,
      });
    } else {
      swal({
        title: "Produto já adicionado",
        text: "Esse produto já foi adicionado, verifique a lista de produtos",
        icon: "warning",
        button: "Ok",
      });
    }
    console.log(formRef.current.values);

    setShowModalSearchProduto(false);
  };

  const onSelectEmpresaHandler = (data, setFieldValue) => {
    setFieldValue("empresa.idEmpresa", data.idEmpresa);
    setFieldValue("empresa.nomeEmpresa", data.nomeEmpresa);
    setShowModalSearchEmpresa(false);
  };

  const handleDeleteProduto = (idProduto, index) => {
    removeRef.current(index);
  };

  const handleSubmit = async (values) => {
    values.idUser = JSON.parse(localStorage.getItem("user")).idUser;
    console.log("HANDLE SUBMIT");
    // console.log(JSON.stringify(values, null, 2));
    SwalOpen({
      status: true,
      header: "Enviando dados",
      message: "Carregando...",
      style: "info",
      type: "info",
    });

    // TODO registraOperacaoEmpresa
    await registraOperacaoEmpresa(values)
      .then((response) => {
        if (response.status === 200) {
          SwalOpen({
            status: true,
            header: "Dados salvos com sucesso",
            message: "Essa mensagem desaparecerá",
            style: "success",
            type: "success",
            timer: 3000,
          });
          ComprovanteEmpresa({
            empresa: {
              idEmpresa: values.empresa.idEmpresa,
              nomeEmpresa: values.empresa.nomeEmpresa,
            },
            produto: values.saida_produto_empresa,
          });
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        SwalOpen({
          status: true,
          header: "Algo deu errado",
          message: error.message,
          style: "error",
          type: "error",
          timer: 3000,
        });
      });
  };

  return (
    <>
      <Layout>
        <div className="form-container">
          <h4>Saída de Produto para Entidade</h4>
          <Formik
            initialValues={{
              empresa: { idEmpresa: 0, nomeEmpresa: "" },
              saida_produto_empresa: [],
            }}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <>
                <SearchEmpresaModal
                  title={"Pesquisar Entidade"}
                  show={showModalSearchEmpresa}
                  hide={() => setShowModalSearchEmpresa(false)}
                  data={empresaData}
                  onSelect={(data) =>
                    onSelectEmpresaHandler(data, setFieldValue)
                  }
                />
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Form.Control
                      type="hidden"
                      name="empresa.idEmpresa"
                      value={values.empresa.idEmpresa}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Col xs="md">
                      <Form.Group controlId="empresa.nomeEmpresa">
                        <Form.Label>Nome</Form.Label>
                        <InputGroup size="sm" className="mb-3">
                          <Form.Control
                            type="text"
                            aria-label="Buscar"
                            aria-describedby="button-search-entidade"
                            placeholder="Destinatário"
                            value={values.empresa.nomeEmpresa}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="empresa.nomeEmpresa"
                            disabled
                          />
                          <button
                            className="btn btn-outline-secondary"
                            variante="outline-secondary"
                            id="button-search-entidade"
                            type="button"
                            onClick={() => setShowModalSearchEmpresa(true)}
                          >
                            <FaSearch /> Buscar
                          </button>
                        </InputGroup>
                        {touched?.empresa?.nomeEmpresa &&
                        errors?.empresa?.nomeEmpresa ? (
                          <span className="error-block">
                            {errors?.empresa?.nomeEmpresa}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <FieldArray name="saida_produto_empresa">
                    {({ push, remove, insert, errors }) => (
                      (pushRef.current = push),
                      (removeRef.current = remove),
                      (
                        <div>
                          <Row className="text-center">
                            <Col xs="md">
                              <button
                                className="btn btn-dark"
                                type="button"
                                onClick={() => setShowModalSearchProduto(true)}
                              >
                                Adicionar Produto
                              </button>
                            </Col>
                          </Row>
                          {values.saida_produto_empresa.map(
                            (produto, index) => (
                              <div key={index}>
                                <Row className="row-produto justify-content-center">
                                  <Col xs="lg">
                                    <Form.Group controlId="nomeProduto">
                                      <Form.Label>Produto</Form.Label>
                                      <Form.Control
                                        size="sm"
                                        type="text"
                                        value={
                                          values.saida_produto_empresa[index]
                                            .nomeProduto
                                        }
                                        name={`saida_produto_empresa[${index}].nomeProduto`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled
                                      />
                                      <span className="error-block">
                                        <ErrorMessage
                                          name={`saida_produto_empresa.${index}.nomeProduto`}
                                        />
                                      </span>
                                    </Form.Group>
                                  </Col>
                                  <Col xs="lg">
                                    <Form.Group controlId="codigoNota">
                                      <Form.Label>Código Nota</Form.Label>
                                      <Form.Control
                                        size="sm"
                                        type="text"
                                        value={
                                          values.saida_produto_empresa[index]
                                            .codigoNota
                                        }
                                        name={`saida_produto_empresa[${index}].codigoNota`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="error-block">
                                        <ErrorMessage
                                          name={`saida_produto_empresa.${index}.codigoNota`}
                                        />
                                      </span>
                                    </Form.Group>
                                  </Col>
                                  <Col xs="lg">
                                    <Form.Group controlId="quantidade_saida">
                                      <Form.Label>
                                        Quantidade de Saída
                                      </Form.Label>
                                      <Form.Control
                                        size="sm"
                                        type="number"
                                        value={
                                          values.saida_produto_empresa[index]
                                            .quantidade_saida
                                        }
                                        name={`saida_produto_empresa[${index}].quantidade_saida`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <Form.Text className="text-muted">
                                        Quantidade em estoque do produto é{" "}
                                        {
                                          values.saida_produto_empresa[index]
                                            .quantidadeEstoque
                                        }
                                      </Form.Text>
                                      <span className="error-block">
                                        <ErrorMessage
                                          name={`saida_produto_empresa.${index}.quantidade_saida`}
                                        />
                                      </span>
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    xs="lg"
                                    className="align-self-center text-center justify-content-around"
                                  >
                                    <Button
                                      className="btn btn-sm"
                                      variant="danger"
                                      onClick={() =>
                                        handleDeleteProduto(
                                          values?.saida_produto_empresa[index]
                                            ?.idProduto,
                                          index
                                        )
                                      }
                                    >
                                      Apagar
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                  </FieldArray>

                  <Row className="button-wrapper text-center">
                    <Col xs="md">
                      <button className="btn btn-primary" type="submit">
                        Salvar
                      </button>
                    </Col>
                  </Row>
                </form>
              </>
            )}
          </Formik>
        </div>

        <SearchProdutoModal
          title={"Pesquisar Produto"}
          show={showModalSearchProduto}
          hide={() => setShowModalSearchProduto(false)}
          data={produtoData}
          onSelectProdutoHandler={onSelectProdutoHandler}
        />
      </Layout>
    </>
  );
};

export default SaidaProdutoEmpresaPage;
