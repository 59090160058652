import React, { useState, useEffect, useReducer } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DataTable, { Media } from "react-data-table-component";
import Table from "../Table";
import "./style.css";
import { useNavigate } from "react-router-dom";

export const SearchPessoaModal = (props) => {
  const paginationComponentOptions = {
    rowsPerPageText: "Pessoas por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.idPeople,
      hide: Media.MD,
    },
    {
      name: "Nome",
      selector: (row) => row.nome,
    },
    {
      name: "CPF",
      selector: (row) => row.cpf,
      hide: Media.MD,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() =>
              props.onSelect({
                idPeople: row.idPeople,
                nome: row.nome,
              })
            }
          >
            Selecionar
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.hide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <p>Tipo de busca para {props.idSearch}</p> */}
          <div className="table">
            <Table
              // title={props.title}
              data={props.data}
              columns={columns}
              pending={false}
              paginationComponentOptions={paginationComponentOptions}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchPessoaModal;
