import React from "react";
import styled from "styled-components";
import { HiOutlineX } from "react-icons/hi";
import "./styles.css";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid rgb(209, 209, 209);
  padding: 0 32px 0 16px;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: rgb(209, 209, 209);
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <Input
      id="search"
      type="text"
      placeholder="Pesquisar..."
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton onClick={onClear}>
      <HiOutlineX className="icon-clear" size={24} color={"#757575"} />
    </ClearButton>
  </>
);

export default FilterComponent;
