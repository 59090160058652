import React from "react";
import Container from "react-bootstrap/Container";
import "./style.css";
const Footer = () => {
  return (
    <>
      <Container>
        <div className="footer-container">
          <p>Copyright @ 2022 Nasser Ruiz Rehman</p>
        </div>
      </Container>
    </>
  );
};

export default Footer;
