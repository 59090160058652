import React, { useMemo, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import DataTable, { Media } from "react-data-table-component";
import FilterComponent from "../../components/FilterComponent";
import { GrDocumentPdf } from "react-icons/gr";
import { getAllOperacaoEmpresa, verifyToken } from "../../sources/api";
import { AuthContext } from "../../contexts/auth";
import LoadingSpinner from "../../components/LoadingSpinner";
import Table from "../../components/Table";
import "./style.css";
import { ComprovanteEmpresa } from "../../components/PdfGenerator";
import { FormatDate } from "../../components/Utils";

export const LogOperacoesEmpresaPage = () => {
  const navigate = useNavigate();
  const { authenticated, logout } = useContext(AuthContext);

  const paginationComponentOptions = {
    rowsPerPageText: "Operações por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "ID Operação",
      selector: (row) => row.operacao.idOperacaoSaidaEmpresa,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "Empresa",
      selector: (row) => row.empresa.nomeEmpresa,
      sortable: true,
    },
    {
      name: "CNPJ",
      selector: (row) => row.empresa.cnpj,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "Data Operação",
      selector: (row) => FormatDate(row.operacao.createdAt),
      sortable: true,
    },
    {
      name: "Imprimir",
      button: true,
      cell: (row) => (
        <>
          <button
            className="none-button"
            onClick={() =>
              ComprovanteEmpresa({
                operacao: row.operacao,
                empresa: row.empresa,
                produto: row.produtos,
              })
            }
          >
            <GrDocumentPdf size={24} />
          </button>
        </>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        (async () => {
          await getAllOperacaoEmpresa().then(async (response) => {
            console.log(response);
            setData(response.data);
            setPending(false);
          });
        })();
      }
    })();
  }, []);

  return (
    <>
      <Layout>
        <div className="wrapper-table">
          <div className="table">
            <Table
              title="Operações realizadas para Entidades"
              data={data}
              columns={columns}
              pending={pending}
              paginationComponentOptions={paginationComponentOptions}
              defaultSortFieldId={1}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default LogOperacoesEmpresaPage;
