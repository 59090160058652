import React, { useState, createContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { api, createSession } from "../sources/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [statusCode, setStatusCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(false);

  useEffect(() => {
    const recoveredUser = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    api.defaults.headers.Authorization = `Bearer ${token}`;
    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
    }
    // console.log(recoveredUser);
    // console.log(token);
    setLoading(false);
  }, []);

  const login = async (email, password) => {
    setLoadingLogin(true);

    await createSession(email, password)
      .then((response) => {
        const loggedUser = {
          idUser: response.data.idUser,
          email: response.data.email,
          username: response.data.username,
        };
        const token = response.data.token;

        localStorage.setItem("user", JSON.stringify(loggedUser));
        localStorage.setItem("token", token);
        api.defaults.headers.Authorization = `Bearer ${token}`;

        setUser(loggedUser);
        navigate("/");
      })
      .catch((error) => {
        setLoadingLogin(false);
        console.log(error.response.status);
        setStatusCode(error.response.status);
      });

    // const response = await createSession(email, password);

    // console.log(response.data.status);
  };

  const logout = () => {
    // console.log("logout");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    api.defaults.headers.Authorization = null;
    setUser(null);
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        loading,
        login,
        logout,
        loadingLogin,
        statusCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
