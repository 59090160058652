import "./App.css";

import AppRoutes from "./AppRoutes";

function App() {
  document.title = "Banco de Ração";
  return (
    <div className="app">
      <AppRoutes />
    </div>
  );
}

export default App;
