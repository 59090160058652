import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import LoadingSpinner from "../LoadingSpinner";
import FilterComponent from "../FilterComponent";
import { HiArchive, HiCheck, HiSearch, HiOutlinePlus } from "react-icons/hi";
import "./style.css";
import AddLoteModal from "../FormLoteModal/FormLoteModal";
import Button from "react-bootstrap/esm/Button";
export const Table = (props) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredItems = props.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  useState(() => {}, [props.setLotes]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        {props.type === "lotes" && (
          <div>
            <Button
              // onClick={() => setShowAddLote(true)}
              onClick={() => props.FormLote()}
              className=" mr-10 button-table"
              size="sm"
            >
              <HiOutlinePlus />
              Novo Lote
            </Button>
          </div>
        )}
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </>
    );
  });

  return (
    <>
      <DataTable
        title={props.title}
        pagination
        highlightOnHover={true}
        pointerOnHover
        striped
        defaultSortAsc={false}
        columns={props.columns}
        data={filteredItems}
        paginationComponentOptions={props.paginationComponentOptions}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        progressPending={props.pending}
        defaultSortFieldId={
          props.defaultSortFieldId ? props.defaultSortFieldId : null
        }
        progressComponent={<LoadingSpinner />}
        noDataComponent={"Nenhum dado para exibir."}
      />
    </>
  );
};

export default Table;
