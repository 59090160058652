import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { HiCheck } from "react-icons/hi";
import { updateLote, getLotesFromProduto } from "../../sources/api";
import * as yup from "yup";
const ModalAlterLote = ({
  showModalAltLote,
  setShowModalAltLote,
  loteToEdit,
  setLoteToEdit,
  idProduto,
  setLotes,
}) => {
  const [initialValues, setInitialValues] = useState({
    lote: { ...loteToEdit },
  });

  console.log("loteToEdit", loteToEdit);
  console.log("initialValues", initialValues);

  useEffect(() => {
    setInitialValues({ lote: { ...loteToEdit } });
  }, [loteToEdit]);

  const schema = yup.object({
    lote: yup.object({
      codigoNota: yup.number().required("Campo obrigatório"),
      quantidadeEstoque: yup.number().required("Campo obrigatório"),
      motivoAlteracao: yup.string().required("Campo obrigatório"),
    }),
  });

  const handleSubmit = async (values) => {
    values.lote.tipoAlteracao = "Edição";
    // values.lote.motivoAlteracao = "Alteração de lote";

    const user = localStorage.getItem("user");
    values.lote.fk_idUser = JSON.parse(user).idUser;

    await updateLote(values.lote).then(async (response) => {
      if (response.status === 200) {
        await getLotesFromProduto(idProduto).then((response) => {
          if (response.status === 200) {
            setLotes(response.data);
          }
        });
        setShowModalAltLote(false);
      }
    });
  };

  return (
    <Modal
      show={showModalAltLote}
      onHide={() => setShowModalAltLote(false)}
      size="lg"
      aria-labelledby="contained-modal-title-center"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Entrada de Produto lote
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Form.Group
                controlId="validationFormik01"
                onSubmit={handleSubmit}
              >
                <Form.Label>Código da Nota</Form.Label>
                <Form.Control
                  type="number"
                  name="lote.codigoNota"
                  value={values.lote.codigoNota || ""}
                  onChange={handleChange}
                  isValid={
                    values?.lote?.codigoNota && !errors?.lote?.codigoNota
                  }
                  disabled={true}
                  isInvalid={!!errors?.lote?.codigoNota}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.lote?.codigoNota}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Código da Nota só pode ser alterado na página do produto.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="validationFormik02">
                <Form.Label>Quantidade em Estoque</Form.Label>
                <Form.Control
                  type="number"
                  name="lote.quantidadeEstoque"
                  value={values.lote.quantidadeEstoque || 0}
                  onChange={handleChange}
                  isValid={
                    values?.lote?.quantidadeEstoque &&
                    !errors?.lote?.quantidadeEstoque
                  }
                  isInvalid={!!errors?.lote?.quantidadeEstoque}
                  // disabled={idLote ? true : false}
                />

                <Form.Control.Feedback type="invalid">
                  {errors?.lote?.quantidadeEstoque}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationFormik03">
                <Form.Label>Motivo da Alteração</Form.Label>
                <Form.Control
                  type="text"
                  name="lote.motivoAlteracao"
                  value={values.lote.motivoAlteracao || ""}
                  onChange={handleChange}
                  isValid={
                    values?.lote?.motivoAlteracao &&
                    !errors?.lote?.motivoAlteracao
                  }
                  isInvalid={!!errors?.lote?.motivoAlteracao}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.lote?.motivoAlteracao}
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "flex-column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "1rem",
                  }}
                >
                  <Button type="submit" variant="success">
                    <HiCheck />
                    Enviar Dados
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowModalAltLote(false)}
                  >
                    Cancelar
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAlterLote;
