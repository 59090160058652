import React, { useState, useEffect } from "react";
import swal from "@sweetalert/with-react";

// const swalRef = () => swal({});

export const SwalClose = () => {
  swal.close();
};

export const SwalOpen = (props) => {
  swal({
    title: props.header,
    text: props.message,
    icon: props.type,
    content: {
      element: "strong",
      attributes: {
        innerText: props.response ? props.response : null,
      },
    },
    allowOutsideClick: false,
    closeOnEsc: false,
    closeOnClickOutside: false,
    timer: props.timer ? props.timer : 99999999,
    buttons: false,
  });
  // setInterval(function () {
  // n > 1 && e.text(--n);
  // }, 1000);
};

// <SweetAlert
//   show={props.show}
//   type={props.type}
//   title={props.header}
//   onConfirm={props.onConfirm}
//   showConfirm={false}
//   timeout={props.timeout ? props.timeout : 3000}
// >
//   <p>{props.message}</p>
//   {props.type !== "info" && (
//     <small>Você será redirecionado em 3 segundos!</small>
//   )}
// </SweetAlert>
// };

// export default AlertModal;
