import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import Layout from "../../components/Layout";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import ContactForm from "../../components/ContactForm";
import { SwalOpen, SwalClose } from "../../components/AlertModal";
import {
  createPeople,
  verifyToken,
  getPeopleDataById,
  deleteContatoPessoa,
} from "../../sources/api";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import { Formik, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import "./style.css";
import {
  SolicitacaoFormal,
  DeclaracaoPobreza,
} from "../../components/PdfGenerator";

export const PeoplePage = () => {
  const navigate = useNavigate();
  const { authenticated, logout } = useContext(AuthContext);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  // const query = new URLSearchParams(useLocation().search);
  const removeRef = useRef();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    pessoa: {
      nome: "",
      nis: "",
      sexo: "Não informado",
      cpf: "",
      rg: "",
      data_nascimento: "",
      endereco_cep: "",
      endereco_rua: "",
      endereco_numero: "",
      endereco_complemento: "",
      endereco_bairro: "",
      endereco_municipio: "",
      endereco_uf: "",
      quantidade_animais: 0,
      quantidade_cachorros: 0,
      quantidade_gatos: 0,
    },
    contatos: [
      // {
      //   contato_telefone: "(43) 99672-4952",
      //   contato_nome: "Nasser",
      //   contato_vinculo: "Pai",
      //   contato_tipo: "Celular",
      // },
    ],
  });

  yup.addMethod(yup.string, "checkEmptyString", function () {
    return this.transform((value) => (value === "" ? undefined : value));
  });

  const schema = yup
    .object()
    .shape({
      pessoa: yup.object().shape({
        nome: yup.string().required("Nome é obrigatório"),
        nis: yup
          .string()
          .required("NIS é obrigatório")
          .min(0)
          .length(11, "NIS inválido")
          .matches(/[0-9]{11}/, "Insira somente números"),
        sexo: yup.string(),
        cpf: yup
          .string()
          .required("CPF é um campo obrigatório")
          .length(14, "14 dígitos necessários")
          .matches(/[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/, "CPF inválido"),
        rg: yup.string().required("RG é um campo obrigatório"),
        data_nascimento: yup
          .string()
          .required("É necessário informar a data de nascimento")
          .matches(
            /^\d{4}-(((0)[0-9])|((1)[0-2]))-([0-2][0-9]|(3)[0-1])$/,
            "Data de nascimento inválida"
          ),
        endereco_cep: yup
          .string()
          .required("CEP é obrigatório")
          .matches(/([0-9]{5})-([0-9]{3})/, "CEP inválido"),
        endereco_rua: yup.string().required("Rua é obrigatório"),
        endereco_numero: yup.string().required("É necessário um número"),
        endereco_complemento: yup
          .string()
          .required("Complemento é obrigatório"),
        endereco_bairro: yup.string().required("Bairro é obrigatório"),
        endereco_municipio: yup.string().required("Município é obrigatório"),
        endereco_uf: yup.string().required("Estado é obrigatório"),
        quantidade_animais: yup
          .number()
          .positive("Número deve ser positivo ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
        quantidade_cachorros: yup
          .number()
          .positive("Número deve ser positivo ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
        quantidade_gatos: yup
          .number()
          .positive("Número deve ser positivo ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
      }),
      contatos: yup.array().of(
        yup.object().shape({
          contato_telefone: yup
            .string()
            .required("Insira o número de telefone")
            .matches(
              /^\(([0-9]{2})\)\W([0-9]{5})-([0-9]{4})/,
              "Número inválido."
            ),
          contato_nome: yup.string().required("Insira o nome do contato"),
          contato_tipo: yup.string().required("Insira o tipo do contato"),
          contato_vinculo: yup.string().required("Insira o vínculo do contato"),
        })
      ),
    })
    .required();

  // const initialPessoaFormValues = ;

  const initialContatosFormValues = {
    contatos: [],
  };

  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        if (id) {
          (async () => {
            await setTimeout(
              SwalOpen({
                status: true,
                header: "Buscando dados...",
                message: "Aguarde",
                allowOutsideClick: false,
                timer: 3000,
                type: "info",
              }),
              3000
            );
          })();

          await getPeopleDataById(id).then(async (response) => {
            console.log(response);
            await setTimeout(function () {
              setInitialValues(response);

              SwalOpen({
                status: true,
                header: "Busca concluida",
                message: "Esta mensagem desaparecerá",
                type: "success",
                timer: 3000,
              });
            }, 2000);
          });
        }
      }
    })();
  }, [reducerValue, id, logout, navigate]);

  const handleDeleteContato = async (idContato, index) => {
    if (idContato) {
      const response = await deleteContatoPessoa(idContato);
      console.log(response);
      if (response === "success") {
        removeRef.current(index);
      }
    } else {
      removeRef.current(index);
    }
  };

  const handleSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));
    if (id) {
      data.pessoa.idPeople = id;
    }
    SwalOpen({
      status: true,
      header: "Enviando dados",
      message: "Carregando...",
      style: "info",
      type: "info",
    });
    await createPeople(data.pessoa, data.contatos)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          SwalOpen({
            status: true,
            header: "Erro",
            message: response.error_message,
            type: "warning",
            style: "error",
            timer: 3000,
          });
        } else {
          SwalOpen({
            status: true,
            header: "Sucesso",
            message: "Cadastro efetuado com sucesso!",
            type: "success",
            timer: 3000,
          });
          setTimeout(() => {
            navigate(`/editar-pessoa/${response.data}`);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.error_message);
        SwalOpen({
          status: true,
          header: "Erro",
          message: "Houve um erro no cadastro, tente novamente!\n",
          style: "error",
          response: error?.response?.data?.error_message,
          type: "error",
          timer: 4000,
        });
      });
    forceUpdate();
  };

  const handleGenerateSolicitacaoFormal = () => {
    SolicitacaoFormal(initialValues);
  };

  const handleGenerateDeclaracaoPobreza = () => {
    DeclaracaoPobreza(initialValues);
  };
  // const handleSubmit = (data) => {};

  return (
    <>
      <Layout>
        <div className="form-container">
          <h4>Formulário de Cadastro</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} id="cadastroForm">
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="nome">
                      <Form.Label>Nome Completo</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="pessoa.nome"
                        placeholder="Nome Completo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pessoa.nome}
                      />
                      {touched?.pessoa?.nome && errors?.pessoa?.nome ? (
                        <span className="error-block">
                          {errors?.pessoa?.nome}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="nis">
                      <Form.Label>NIS</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="pessoa.nis"
                        placeholder="NIS"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pessoa.nis}
                      />
                      {touched?.pessoa?.nis && errors.pessoa?.nis ? (
                        <span className="error-block">
                          {errors?.pessoa?.nis}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="sexo">
                      <Form.Label>Sexo</Form.Label>
                      <Form.Select
                        size="sm"
                        name="pessoa.sexo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pessoa.sexo}
                      >
                        <option>Não informado</option>
                        <option>Masculino</option>
                        <option>Feminino</option>
                      </Form.Select>
                    </Form.Group>
                    {touched?.pessoa?.sexo && errors?.pessoa?.sexo ? (
                      <span className="error-block">{errors.sexo}</span>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="cpf">
                      <Form.Label>CPF</Form.Label>
                      <InputMask
                        className="form-control form-control-sm"
                        mask={"999.999.999-99"}
                        maskChar={""}
                        size="sm"
                        name="pessoa.cpf"
                        value={values.pessoa.cpf}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        {...(id && { disabled: true })}
                      />
                      {touched?.pessoa?.cpf && errors.pessoa?.cpf ? (
                        <span className="error-block">
                          {errors?.pessoa?.cpf}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="rg">
                      <Form.Label>RG</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="pessoa.rg"
                        value={values.pessoa.rg}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.rg && errors?.pessoa?.rg ? (
                        <span className="error-block">
                          {errors?.pessoa?.rg}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="data_nascimento">
                      <Form.Label>Data de Nascimento</Form.Label>
                      <Form.Control
                        className="form-control form-control-sm"
                        type="date"
                        name="pessoa.data_nascimento"
                        value={values.pessoa.data_nascimento}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.data_nascimento &&
                      errors?.pessoa?.data_nascimento ? (
                        <span className="error-block">
                          {errors?.pessoa?.data_nascimento}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="md-3">
                    <Form.Group controlId="endereco_cep">
                      <Form.Label>CEP</Form.Label>
                      <InputMask
                        className="form-control form-control-sm"
                        mask={"99999-999"}
                        maskChar={""}
                        value={values.pessoa.endereco_cep}
                        name="pessoa.endereco_cep"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.endereco_cep &&
                      errors?.pessoa?.endereco_cep ? (
                        <span className="error-block">
                          {errors.pessoa?.endereco_cep}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_rua">
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        value={values.pessoa.endereco_rua}
                        name="pessoa.endereco_rua"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.endereco_rua &&
                      errors?.pessoa?.endereco_rua ? (
                        <span className="error-block">
                          {errors?.pessoa?.endereco_rua}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md-2">
                    <Form.Group controlId="endereco_numero">
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.pessoa.endereco_numero}
                        name="pessoa.endereco_numero"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.endereco_numero &&
                      errors?.pessoa?.endereco_numero ? (
                        <span className="error-block">
                          {errors?.pessoa?.endereco_numero}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="endereco_complemento">
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.pessoa.endereco_complemento}
                        name="pessoa.endereco_complemento"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.endereco_complemento &&
                      errors?.pessoa?.endereco_complemento ? (
                        <span className="error-block">
                          {errors.pessoa?.endereco_complemento}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_bairro">
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.pessoa.endereco_bairro}
                        name="pessoa.endereco_bairro"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.endereco_bairro &&
                      errors?.pessoa?.endereco_bairro ? (
                        <span className="error-block">
                          {errors?.pessoa?.endereco_bairro}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_municipio">
                      <Form.Label>Município</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.pessoa.endereco_municipio}
                        name="pessoa.endereco_municipio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.endereco_municipio &&
                      errors?.pessoa?.endereco_municipio ? (
                        <span className="error-block">
                          {errors.pessoa?.endereco_municipio}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_uf">
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.pessoa.endereco_uf}
                        name="pessoa.endereco_uf"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.endereco_uf &&
                      errors?.pessoa?.endereco_uf ? (
                        <span className="error-block">
                          {errors?.pessoa?.endereco_uf}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col xs="md">
                    <Form.Group controlId="quantidade_animais">
                      <Form.Label>Quantidade de Animais</Form.Label>
                      <Form.Control
                        size="sm"
                        type="number"
                        value={values.pessoa.quantidade_animais}
                        name="pessoa.quantidade_animais"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.quantidade_animais &&
                      errors?.pessoa?.quantidade_animais ? (
                        <span className="error-block">
                          {errors?.pessoa?.quantidade_animais}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="quantidade_cachorros">
                      <Form.Label>Quantidade de Cachorros</Form.Label>
                      <Form.Control
                        size="sm"
                        type="number"
                        value={values.pessoa.quantidade_cachorros}
                        name="pessoa.quantidade_cachorros"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.quantidade_cachorros &&
                      errors?.pessoa?.quantidade_cachorros ? (
                        <span className="error-block">
                          {errors?.pessoa?.quantidade_cachorros}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="quantidade_gatos">
                      <Form.Label>Quantidade de Gatos</Form.Label>
                      <Form.Control
                        size="sm"
                        type="number"
                        value={values.pessoa.quantidade_gatos}
                        name="pessoa.quantidade_gatos"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.pessoa?.quantidade_gatos &&
                      errors?.pessoa?.quantidade_gatos ? (
                        <span className="error-block">
                          {errors?.pessoa?.quantidade_gatos}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <h3>Contatos</h3>
                </Row>
                <div id="contacts-append">
                  <FieldArray name="contatos">
                    {({ push, remove, errors }) => (
                      (removeRef.current = remove),
                      (
                        <div>
                          {values.contatos.map((contato, index) => (
                            <div key={index}>
                              <Row className="justify-content-center">
                                <Col xs="md">
                                  <Form.Group controlId="contato_telefone">
                                    <Form.Label>Telefone</Form.Label>
                                    <InputMask
                                      size="sm"
                                      type="text"
                                      className="form-control form-control-sm"
                                      mask={"(99) 99999-9999"}
                                      maskChar={""}
                                      value={
                                        values.contatos[index].contato_telefone
                                      }
                                      name={`contatos[${index}].contato_telefone`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_telefone`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs="md">
                                  <Form.Group controlId="contato_tipo">
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select
                                      size="sm"
                                      className="form-control form-control-sm"
                                      name={`contatos[${index}.contato_tipo]`}
                                      value={
                                        values.contatos[index].contato_tipo
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option>Não informado</option>
                                      <option>Residencial</option>
                                      <option>Comercial</option>
                                      <option>Celular</option>
                                    </Form.Select>
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_tipo`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs="md">
                                  <Form.Group controlId="contato_nome">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                      size="sm"
                                      className="form-control form-control-sm"
                                      name={`contatos[${index}].contato_nome`}
                                      value={
                                        values.contatos[index].contato_nome
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_nome`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs="md">
                                  <Form.Group controlId="contato_vinculo">
                                    <Form.Label>Vinculo</Form.Label>
                                    <Form.Control
                                      sm="sm"
                                      type="text"
                                      className="form-control form-control-sm"
                                      name={`contatos[${index}].contato_vinculo`}
                                      value={
                                        values.contatos[index].contato_vinculo
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_vinculo`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col
                                  xs="md"
                                  className="align-self-end justify-content-around"
                                >
                                  <Button
                                    className="btn btn-sm"
                                    variant="danger"
                                    onClick={() =>
                                      handleDeleteContato(
                                        values?.contatos[index]?.idContato,
                                        index
                                      )
                                    }
                                  >
                                    Apagar
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <Row>
                            <Col
                              xs="md"
                              className="button-wrapper auto justify-content-around"
                              id="buttonAppendContato"
                            >
                              <button
                                className="btn btn-sm btn-warning"
                                type="button"
                                onClick={() =>
                                  push({
                                    contato_telefone: "",
                                    contato_vinculo: "",
                                    contato_nome: "",
                                    contato_tipo: "Não informado",
                                  })
                                }
                              >
                                Adicionar Contato
                              </button>
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
                  </FieldArray>
                </div>
                <Row className="form-bottom justify-content-end">
                  <Col
                    xs="md"
                    className="button-wrapper auto justify-content-around"
                  >
                    {id ? (
                      <>
                        <button
                          className="btn btn-sm btn-secondary"
                          type="button"
                          onClick={handleGenerateSolicitacaoFormal}
                        >
                          Gerar solicitação Formal
                        </button>
                        <button
                          className="btn btn-sm btn-secondary"
                          type="button"
                          onClick={handleGenerateDeclaracaoPobreza}
                        >
                          Gerar Declaração de Pobreza
                        </button>
                      </>
                    ) : null}

                    <button className="btn btn-sm btn-success" type="submit">
                      Salvar Cadastro
                    </button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        </div>
      </Layout>
      {/* {id && <SolicitacaoFormal />} */}
    </>
  );
};

export default PeoplePage;
