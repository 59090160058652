import React, { useContext, useState, useEffect, useReducer } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { HiArchive, HiCheck, HiSearch } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { Formik, ErrorMessage } from "formik";
import {
  verifyToken,
  createProduto,
  getProdutoDataById,
  getProdutoDataByIdTeste,
} from "../../sources/api";
import { SwalOpen } from "../../components/AlertModal";
import Layout from "../../components/Layout";
import * as yup from "yup";
import LoteModal from "../../components/LoteModal/LoteModal";
import "./style.css";

export const ProdutoPage = () => {
  const navigate = useNavigate();
  const { authenticated, logout } = useContext(AuthContext);
  const [initialValues, setInitialValues] = useState({
    produto: {
      nomeProduto: "",
      categoria: "Cachorros",
      observacoes: "",
    },
  });

  const [lotes, setLotes] = useState([]);
  const { id } = useParams();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showModal, setShowModal] = useState(false);

  const schema = yup
    .object()
    .shape({
      produto: yup.object().shape({
        nomeProduto: yup.string().required("Nome do produto é obrigatório"),
        categoria: yup.string().required("Uma categoria deve ser informada"),
        observacoes: yup.string(),
      }),
    })
    .required();

  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        if (id) {
          await getProdutoDataByIdTeste(id).then(async (response) => {
            if (response.status === 200) {
              setInitialValues({ produto: response.data.produto });
              setLotes(response.data.lotes);
            } else {
              navigate("/pesquisar-produto");
            }
          });
        }
      }
    })();
  }, [reducerValue, id, logout, navigate]);

  const handleLoteClick = () => {
    setShowModal(true);
  };

  const handleSubmit = async (data) => {
    console.log(JSON.stringify(data, null, 2));
    SwalOpen({
      status: true,
      header: "Enviando dados",
      message: "Carregando...",
      style: "info",
      type: "info",
    });
    await createProduto(data.produto)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          SwalOpen({
            status: true,
            header: "Erro",
            message: response.error_message,
            type: "warning",
            style: "error",
            timer: 3000,
          });
        } else {
          SwalOpen({
            status: true,
            header: "Sucesso",
            message: "Cadastro efetuado com sucesso!",
            type: "success",
            timer: 3000,
          });
          setTimeout(() => {
            navigate(`/editar-produto/${response.data}`);
          });
        }
      })
      .catch((error) => {
        SwalOpen({
          status: true,
          header: "Erro",
          message: "Houve um erro no cadastro, tente novamente mais tarde!\n",
          style: "error",
          response: error?.response?.data?.error_message,
          type: "error",
          timer: 4000,
        });
      });
    forceUpdate();
  };

  return (
    <>
      <Layout>
        <div className="form-container">
          <h4>Cadastro de Produto</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} id="cadastroProdutoForm">
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="nomeProduto">
                      <Form.Label>Nome do Produto</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="produto.nomeProduto"
                        placeholder="Nome do produto"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.produto.nomeProduto}
                      />
                      {touched?.produto?.nomeProduto &&
                      errors?.produto?.nomeProduto ? (
                        <span className="error-block">
                          {errors?.produto?.nomeProduto}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col cs="md">
                    <Form.Group controlId="categoria">
                      <Form.Label>Categoria</Form.Label>
                      <Form.Select
                        size="sm"
                        name="produto.categoria"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.produto.categoria}
                      >
                        <option>Cachorros</option>
                        <option>Gatos</option>
                      </Form.Select>
                      {touched?.produto?.categoria &&
                      errors?.produto?.categoria ? (
                        <span className="error-block">
                          {errors?.produto?.categoria}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col cs="md">
                    <Form.Group controlId="observacoes">
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        size="sm"
                        as="textarea"
                        rows={3}
                        name="produto.observacoes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.produto.observacoes}
                      />
                      {touched?.produto?.observacoes &&
                      errors?.produto?.observacoes ? (
                        <span className="error-block">
                          {errors?.produto?.observacoes}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="text-end last-row">
                  <Col xs="md">
                    <Button onClick={() => navigate("/pesquisar-produto")}>
                      <HiSearch /> Procurar
                    </Button>
                    {id && (
                      <Button
                        onClick={() => handleLoteClick()}
                        className="btn btn-secondary button-group"
                      >
                        <HiArchive /> Lote
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className="btn btn-success button-group"
                    >
                      <HiCheck /> Salvar
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        </div>
        <LoteModal
          show={showModal}
          setShow={setShowModal}
          lotes={lotes}
          setLotes={setLotes}
          idProduto={id}
        />
        ;
      </Layout>
    </>
  );
};

export default ProdutoPage;
