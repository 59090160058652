import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import logo from "../../assets/images/logo.svg";
import logoNew from "../../assets/images/nav-logo.png";
import { MdLogout } from "react-icons/md";

import { AuthContext } from "../../contexts/auth";

const NavigationBar = () => {
  const { logout, user } = useContext(AuthContext);

  // console.log(user);

  const handleLogout = () => {
    logout();
  };

  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logoNew}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Projeto Banco de Ração
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Entidade" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/cadastrar-pessoa">
                Cadastro de Pessoa
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/cadastrar-empresa">
                Cadastro de Entidade
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/pesquisar-pessoa">
                Pesquisar Pessoa
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/pesquisar-empresa">
                Pesquisar Entidade
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Produto" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/cadastrar-produto">
                Cadastro Produto
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/pesquisar-produto">
                Pesquisar Produtos
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Saída/Entrada" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/saida-produto-pessoa">
                Saída de Produtos - Pessoas
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/saida-produto-empresa">
                Saída de Produtos - Entidade
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/entrada-de-produto">
                Entrada de Produtos
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Relatórios" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/operacoes-pessoa">
                Saída Pessoas
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/operacoes-empresa">
                Saída Entidade
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/evolucao-estoque">
                Evolução Estoque
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Navbar.Text>Bem vindo, {user.username}!</Navbar.Text>
            <Nav.Link onClick={handleLogout} href="#">
              <MdLogout /> Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
