import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Media } from "react-data-table-component";
import FormLoteModal from "../FormLoteModal/FormLoteModal";
import Table from "../Table";
const LoteModal = ({ show, setShow, lotes, idProduto, setLotes }) => {
  const [showFormLote, setShowFormLote] = useState(false);
  const [loteSelected, setLoteSelected] = useState({});

  const paginationComponentOptions = {
    rowsPerPageText: "Lotes por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "ID Lote",
      selector: (row) => row.idLote,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "Código Nota",
      selector: (row) => row.codigoNota,
      sortable: true,
    },
    {
      name: "Quantidade em estoque",
      selector: (row) => row.quantidadeEstoque,
      sortable: true,
    },
    {
      name: "Editar",
      button: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => editFormLote(row)}
          >
            Editar
          </button>
        </>
      ),
    },
  ];

  const editFormLote = (data) => {
    setLoteSelected(data);
    setShowFormLote(true);
  };

  const FormLote = () => {
    setLoteSelected({});
    setShowFormLote(true);
  };

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Gerenciar Lote do Produto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table">
            <Table
              data={lotes}
              setLotes={setLotes}
              columns={columns}
              pending={false}
              paginationComponentOptions={paginationComponentOptions}
              type="lotes"
              idProduto={idProduto}
              FormLote={FormLote}
            />
          </div>
          <FormLoteModal
            showFormLote={showFormLote}
            setShowFormLote={setShowFormLote}
            idProduto={idProduto}
            setLotes={setLotes}
            lote={loteSelected}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoteModal;
