import swal from "@sweetalert/with-react";
import { ErrorMessage, FieldArray, Formik } from "formik";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { SwalOpen } from "../../components/AlertModal";
import Layout from "../../components/Layout";
import { ComprovantePessoa } from "../../components/PdfGenerator";
import SearchPessoaModal from "../../components/SearchPessoaModal";
import SearchProdutoModal from "../../components/SearchProdutoModal";
import { AuthContext } from "../../contexts/auth";
import {
  getAllPeopleData,
  getAllProdutoData,
  registraOperacao,
  verifyToken,
} from "../../sources/api";
import "./style.css";

export const SaidaProdutoPeoplePage = () => {
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const { authenticated, logout } = useContext(AuthContext);
  const [peopleData, setPeopleData] = useState();
  const [produtoData, setProdutoData] = useState();
  const [showModalSearchPeople, setShowModalSearchPeople] = useState(false);
  const [showModalSearchProduto, setShowModalSearchProduto] = useState(false);
  const removeRef = useRef();
  const pushRef = useRef();
  const formRef = useRef();

  const schema = yup
    .object()
    .shape({
      pessoa: yup.object().shape({
        idPeople: yup.number().positive().integer().min(1).required(),
        nome: yup.string().required("É necessário selecionar uma pessoa"),
      }),
      saida_produto_people: yup.array().of(
        yup.object().shape({
          idProduto: yup
            .number("Precisa ser um número")
            .positive("Precisa ser positivo")
            .integer("Inteiro")
            .min(1, "Pelo menos 1")
            .required(),
          nomeProduto: yup
            .string("Precisa ser uma string")
            .required("Produto é requerido"),
          categoria: yup
            .string("Precisa ser uma string")
            .required("Categoria é requerida"),
          quantidade_saida: yup
            .number("Precisa ser um número")
            .positive("Número positivo")
            .integer("Número inteiro")
            .min(1, "Precisa ser maior que 0 ou 1")
            .required("Uma quantidade é necessária")
            .when(
              "quantidadeEstoque",
              (quantidadeEstoque, field) =>
                quantidadeEstoque &&
                field.max(
                  quantidadeEstoque,
                  `Quantidade não pode ser maior que ${quantidadeEstoque}`
                )
            ),
          quantidadeEstoque: yup
            .number("Precisa ser um número")
            .positive("Número positivo")
            .integer("Número inteiro")
            .min(1, "Precisa ser maior que 0 ou 1")
            .required("Uma quantidade é necessária"),
        })
      ),
    })
    .required();

  // TODO GET all data from people and empresa pass for props to modal
  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        (async () => {
          SwalOpen({
            status: true,
            header: "Buscando dados...",
            message: "Aguarde",
            allowOutsideClick: false,
            timer: 3000,
            type: "info",
          });
          await getAllPeopleData().then(async (response) => {
            setPeopleData(response.data);
          });
          await getAllProdutoData()
            .then(async (response) => {
              setProdutoData(response.data);
            })
            .then(() => {
              SwalOpen({
                status: true,
                header: "Busca concluida",
                message: "Esta mensagem desaparecerá",
                type: "success",
                timer: 3000,
              });
            });
        })();
      }
    })();
  }, [reducerValue]);

  const onSelectProdutoHandler = async (data) => {
    console.log(formRef.current.values);
    const validator =
      (await formRef.current.values?.saida_produto_people.filter((element) => {
        console.log("LOTES" + element.idLote, data.idLote);
        return element.idLote === data.idLote;
      })) || [];
    console.log(validator);
    if (validator.length === 0) {
      pushRef.current({
        idProduto: data.idProduto,
        nomeProduto: data.nomeProduto,
        categoria: data.categoria,
        idLote: data.idLote,
        codigoNota: data.codigoNota,
        quantidadeEstoque: data.quantidadeEstoque,
        quantidade_saida: 0,
      });
    } else {
      swal({
        title: "Produto já adicionado",
        text: "Esse produto já foi adicionado, verifique a lista de produtos",
        icon: "warning",
        button: "Ok",
      });
    }
    console.log(formRef.current.values);

    setShowModalSearchProduto(false);
  };

  const onSelectPeopleHandler = (data, setFieldValue) => {
    setFieldValue("pessoa.idPeople", data.idPeople);
    setFieldValue("pessoa.nome", data.nome);
    setShowModalSearchPeople(false);
  };

  const handleDeleteProduto = (idProduto, index) => {
    removeRef.current(index);
  };

  const handleSubmit = async (values) => {
    values.idUser = JSON.parse(localStorage.getItem("user")).idUser;
    console.log("HANDLE SUBMIT");
    console.log(JSON.stringify(values, null, 2));
    SwalOpen({
      status: true,
      header: "Enviando dados",
      message: "Carregando...",
      style: "info",
      type: "info",
    });

    await registraOperacao(values)
      .then((response) => {
        if (response.status === 200) {
          SwalOpen({
            status: true,
            header: "Dados salvos com sucesso",
            message: "Essa mensagem desaparecerá",
            style: "success",
            type: "success",
            timer: 3000,
          });
          ComprovantePessoa({
            people: values.pessoa,
            produto: values.saida_produto_people,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        SwalOpen({
          status: true,
          header: "Algo deu errado",
          message: error.message,
          style: "error",
          type: "error",
          timer: 3000,
        });
      });
  };

  return (
    <>
      <Layout>
        <div className="form-container">
          <h4>Saída de Produto para Pessoa Física</h4>
          <Formik
            initialValues={{
              pessoa: { idPeople: 0, nome: "" },
              saida_produto_people: [],
            }}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <>
                <SearchPessoaModal
                  title={"Pesquisar Pessoa"}
                  show={showModalSearchPeople}
                  hide={() => setShowModalSearchPeople(false)}
                  data={peopleData}
                  onSelect={(data) =>
                    onSelectPeopleHandler(data, setFieldValue)
                  }
                />
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Form.Control
                      type="hidden"
                      name="pessoa.idPeople"
                      value={values.pessoa.idPeople}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Col xs="md">
                      <Form.Group controlId="pessoa.nome">
                        <Form.Label>Nome</Form.Label>
                        <InputGroup size="sm" className="mb-3">
                          <Form.Control
                            type="text"
                            aria-label="Buscar"
                            aria-describedby="button-search-entidade"
                            placeholder="Destinatário"
                            value={values.pessoa.nome}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="pessoa.nome"
                            disabled
                          />
                          <button
                            className="btn btn-outline-secondary"
                            variante="outline-secondary"
                            id="button-search-entidade"
                            type="button"
                            onClick={() => setShowModalSearchPeople(true)}
                          >
                            <FaSearch /> Buscar
                          </button>
                        </InputGroup>
                        {touched?.pessoa?.nome && errors?.pessoa?.nome ? (
                          <span className="error-block">
                            {errors?.pessoa?.nome}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <FieldArray name="saida_produto_people">
                    {({ push, remove, insert, errors }) => (
                      (pushRef.current = push),
                      (removeRef.current = remove),
                      (
                        <div>
                          <Row className="text-center">
                            <Col xs="md">
                              <button
                                className="btn btn-dark"
                                type="button"
                                onClick={() => setShowModalSearchProduto(true)}
                              >
                                Adicionar Produto
                              </button>
                            </Col>
                          </Row>
                          {values.saida_produto_people.map((produto, index) => (
                            <div key={index}>
                              <Row className="row-produto justify-content-center">
                                <Col xs="lg">
                                  <Form.Group controlId="nomeProduto">
                                    <Form.Label>Produto</Form.Label>
                                    <Form.Control
                                      size="sm"
                                      type="text"
                                      value={
                                        values.saida_produto_people[index]
                                          .nomeProduto
                                      }
                                      name={`saida_produto_people[${index}].nomeProduto`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled
                                    />
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`saida_produto_people.${index}.nomeProduto`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Form.Control
                                  size="sm"
                                  type="hidden"
                                  value={
                                    values.saida_produto_people[index].categoria
                                  }
                                  name={`saida_produto[${index}].categoria`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Col xs="lg">
                                  <Form.Group controlId="codigoNota">
                                    <Form.Label>Código Nota</Form.Label>
                                    <Form.Control
                                      size="sm"
                                      type="text"
                                      value={
                                        values.saida_produto_people[index]
                                          .codigoNota
                                      }
                                      name={`saida_produto_people[${index}].codigoNota`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled
                                    />

                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`saida_produto_people.${index}.codigoNota`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs="lg">
                                  <Form.Group controlId="quantidade_saida">
                                    <Form.Label>Quantidade de Saída</Form.Label>
                                    <Form.Control
                                      size="sm"
                                      type="number"
                                      value={
                                        values.saida_produto_people[index]
                                          .quantidade_saida
                                      }
                                      name={`saida_produto_people[${index}].quantidade_saida`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <Form.Text className="text-muted">
                                      Quantidade em estoque do produto é{" "}
                                      {
                                        values.saida_produto_people[index]
                                          .quantidadeEstoque
                                      }
                                    </Form.Text>
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`saida_produto_people.${index}.quantidade_saida`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col
                                  xs="lg"
                                  className="align-self-center text-center justify-content-around"
                                >
                                  <Button
                                    className="btn btn-sm"
                                    variant="danger"
                                    onClick={() =>
                                      handleDeleteProduto(
                                        values?.saida_produto_people[index]
                                          ?.idProduto,
                                        index
                                      )
                                    }
                                  >
                                    Apagar
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </FieldArray>

                  <Row className="button-wrapper text-center">
                    <Col xs="md">
                      <button className="btn btn-primary" type="submit">
                        Salvar
                      </button>
                    </Col>
                  </Row>
                </form>
              </>
            )}
          </Formik>
        </div>

        <SearchProdutoModal
          title={"Pesquisar Produto"}
          show={showModalSearchProduto}
          hide={() => setShowModalSearchProduto(false)}
          data={produtoData}
          onSelectProdutoHandler={onSelectProdutoHandler}
        />
      </Layout>
    </>
  );
};

export default SaidaProdutoPeoplePage;
