import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./styles.css";
import Button from "react-bootstrap/Button";

const LoginPage = () => {
  const { authenticated, login, loadingLogin, statusCode } =
    useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (() => {
      if (authenticated) {
        navigate("/");
      }
    })();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("submit ", { email, password });
    login(email, password);
  };

  return (
    <div id="login">
      <h1 className="title">Login do sistema</h1>
      <form className="form" onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {loadingLogin === true && <LoadingSpinner />}
        {statusCode === 400 && <div>* Usuário ou senha incorretos!</div>}
        <div className="actions">
          <Button className="btn btn-primary" type="submit">
            Entrar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
