import React from "react";
import Footer from "../Footer";
import NavigationBar from "../NavigationBar";
import Container from "react-bootstrap/Container";
import "./style.css";

export const Layout = ({ children }) => {
  return (
    <>
      <NavigationBar />
      <Container className="main-container">{children}</Container>
      <Footer />
    </>
  );
};

export default Layout;
