import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DataTable, { Media } from "react-data-table-component";
import Table from "../Table";
import { getLotesFromProduto } from "../../sources/api";
import FormLoteModal from "../FormLoteModal/FormLoteModal";

const SearchLoteModal = ({
  show,
  setShow,
  idProduto,
  produtoSelected,
  onSelectProdutoHandler,
}) => {
  // const [showFormLote, setShowFormLote] = useState(false);
  // const [loteSelected, setLoteSelected] = useState({});

  const [lotes, setLotes] = useState();

  useEffect(() => {
    (async () => {
      if (produtoSelected.idProduto !== undefined) {
        await getLotesFromProduto(produtoSelected.idProduto)
          .then((response) => {
            setLotes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })();
    // console.log(produtoSelected.idProduto);
  }, [produtoSelected]);

  const paginationComponentOptions = {
    rowsPerPageText: "Lotes por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "ID Lote",
      selector: (row) => row.idLote,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "Código Nota",
      selector: (row) => row.codigoNota,
      sortable: true,
    },
    {
      name: "Quantidade em estoque",
      selector: (row) => row.quantidadeEstoque,
      sortable: true,
    },
    {
      name: "Ação",
      button: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            disabled={row.quantidadeEstoque === 0}
            onClick={() => selectLote(row)}
          >
            Selecionar
          </button>
        </>
      ),
    },
  ];

  const editFormLote = (data) => {
    // setLoteSelected(data);
    // setShowFormLote(true);
  };

  const FormLote = () => {
    // setLoteSelected({});
    // setShowFormLote(true);
  };

  const selectLote = (data) => {
    const produto = {
      ...produtoSelected,
      idLote: data.idLote,
      codigoNota: data.codigoNota,
      quantidadeEstoque: data.quantidadeEstoque,
    };
    onSelectProdutoHandler(produto);
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Selecionar Lote
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table">
            <Table
              // title={props.title}
              data={lotes}
              setLotes={setLotes}
              columns={columns}
              pending={false}
              paginationComponentOptions={paginationComponentOptions}
              idProduto={idProduto}
              FormLote={FormLote}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchLoteModal;
