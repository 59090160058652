import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

// import "jspdf-autotable";
// import { renderToString } from "react-dom/server";
import { imagePdf, formatDateFull } from "../Utils/index";
// import { applyPlugin } from "jspdf-autotable";

const data = new Date();
const dia = String(data.getDate()).padStart(2, "0");
const mes = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
][data.getMonth()];
const ano = data.getFullYear();

export const RelatorioEvolucaoEstoque = ({ logLotes, lote, produto }) => {
  let doc = new jsPDF("l", "mm", "a4");

  console.log(logLotes);

  var imgData = imagePdf();
  doc.addImage(imgData, "png", 60, 10, 170, 45);
  doc.setFont("times", "bold");
  doc.text("RELATÓRIO DE EVOLUÇÃO DE ESTOQUE", 147.5, 70, "center");
  doc.setFont("times", "normal");
  doc.setFontSize(16);
  doc.text(`Produto`, 20, 80);
  doc.setFontSize(12);
  doc.text(`${produto.nomeProduto}`, 20, 85);
  doc.setFontSize(16);
  doc.text(
    `Número da Nota`,
    doc.internal.pageSize.getWidth() - 30,
    80,
    "right"
  );
  doc.setFontSize(12);
  doc.text(
    `${lote.codigoNota}`,
    doc.internal.pageSize.getWidth() - 30,
    85,
    "right"
  );
  const data = logLotes.map((log) => [
    log.tipoAlteracao,
    log.motivoAlteracao,
    log.valor_anterior,
    log.valor_atual,
    log.valor_atual - log.valor_anterior,
    formatDateFull(log.createdAt),
  ]);

  let content = {
    startY: 90,
    head: [
      [
        "Tipo",
        "Motivo",
        "Valor Anterior",
        "Valor Atual",
        "Quantidade Operação",
        "Data da Operação",
      ],
    ],
    body: data,
    theme: "grid",
    didParseCell: function (data) {
      if (data.section !== "body") return;
      if (data.row.index === 0) {
        data.cell.styles.fillColor = [255, 255, 180];
      }
      if (data.row.raw[4] > 0) {
        data.cell.styles.fillColor = [218, 255, 180];
      } else if (data.row.raw[4] < 0) {
        data.cell.styles.fillColor = [255, 180, 180];
      } else if (data.row.raw[4] === 0 || data.row.index !== 0) {
        data.cell.styles.fillColor = [255, 255, 180];
      }
    },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0],
    },
    bodyStyles: {
      lineColor: [0, 0, 0],
      fontStyle: "bold",
      textColor: [0, 0, 0],
    },
  };

  autoTable(doc, content);
  let finalHeight = doc.lastAutoTable.finalY + 20;

  doc.save(
    `relatorio_${produto.nomeProduto}_${lote.codigoNota}_${dia}-${mes}-${ano}.pdf`
  );
};

export const SolicitacaoFormal = (props) => {
  let doc = new jsPDF("p", "mm", "a4");

  var imgData = imagePdf();

  doc.addImage(imgData, "png", 20, 10, 170, 45);

  doc.setFont("times", "bold");
  doc.text("SOLICITAÇÃO FORMAL", 105, 70, "center");

  let paragraph1 = `       Eu ${props.pessoa.nome}, portador (a) do CPF ${props.pessoa.cpf}, morador (a) do Município de Cornélio Procópio, residente no endereço ${props.pessoa.endereco_rua}, número ${props.pessoa.endereco_numero}, Bairro ${props.pessoa.endereco_bairro}.`;
  let paragraph2 = `       Declaro ser Protetor Individual e através do Decreto Nº 464/2021, solicito meu cadastramento junto ao Cadastro do Banco de Ração, para o recebimento das doações de produtos para alimentação de cães e gatos.`;
  let paragraph3 = `Cornélio Procópio, ${dia} de ${mes} de ${ano}`;
  let paragraph4 = `Assinatura do Responsável`;
  doc.setFont("times", "normal");
  doc.setFontSize(14);
  doc.text(paragraph1, 20, 80, { maxWidth: 170, align: "justify" });
  doc.text(paragraph2, 20, 100, { maxWidth: 170, align: "justify" });
  doc.text(paragraph3, 200, 140, { maxWidth: 100, align: "right" });
  doc.line(70, 170, 140, 170);
  doc.text(paragraph4, 105, 180, "center");

  doc.save("a4.pdf");
};

export const SolicitacaoFormalEmpresa = (props) => {
  let doc = new jsPDF("p", "mm", "a4");

  var imgData = imagePdf();

  doc.addImage(imgData, "png", 20, 10, 170, 45);

  doc.setFont("times", "bold");
  doc.text("SOLICITAÇÃO FORMAL", 105, 70, "center");
  console.log(JSON.stringify(props));
  let paragraph1 = `       A entidade ${props.empresa.nomeEmpresa}, portador (a) do CNPJ ${props.empresa.cnpj}, sediada (a) em ${props.empresa.endereco_municipio}, no endereço ${props.empresa.endereco_rua}, número ${props.empresa.endereco_numero}, Bairro ${props.empresa.endereco_bairro}.`;
  let paragraph2 = `       Declaro ser Entidade de Proteção animal e através do Decreto Nº 464/2021, solicito meu cadastramento junto ao Cadastro do Banco de Ração, para o recebimento das doações de produtos para alimentação de cães e gatos.`;
  let paragraph3 = `Cornélio Procópio, ${dia} de ${mes} de ${ano}`;
  let paragraph4 = `Assinatura do Responsável`;
  doc.setFont("times", "normal");
  doc.setFontSize(14);
  doc.text(paragraph1, 20, 80, { maxWidth: 170, align: "justify" });
  doc.text(paragraph2, 20, 100, { maxWidth: 170, align: "justify" });
  doc.text(paragraph3, 200, 140, { maxWidth: 100, align: "right" });
  doc.line(70, 170, 140, 170);
  doc.text(paragraph4, 105, 180, "center");

  doc.save("a4.pdf");
};

export const ComprovanteEmpresa = (props) => {
  let doc = new jsPDF("p", "mm", "a4");

  var imgData = imagePdf();

  doc.addImage(imgData, "png", 20, 10, 170, 45);

  doc.setFont("times", "bold");
  doc.text("RECIBO", 105, 70, "center");

  let paragraph1 = `A entidade ${props.empresa?.nomeEmpresa}, recebeu do Centro de Recuperação Cães e Gatos (CERCEG), conforme Decreto Nº 464/2021, à doação de ração na quantidade abaixo descrita:`;

  let paragraph2;
  if (props.operacao) {
    const dateProps = new Date(props.operacao.createdAt);
    const propsDia = String(dateProps.getDate()).padStart(2, "0");
    const propsMes = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ][dateProps.getMonth()];
    const propsAno = dateProps.getFullYear();
    paragraph2 = `Cornélio Procópio, ${propsDia} de ${propsMes} de ${propsAno}`;
  } else {
    paragraph2 = `Cornélio Procópio, ${dia} de ${mes} de ${ano}.`;
  }
  let paragraph3 = "Assinatura";

  doc.setFont("times", "normal");
  doc.setFontSize(12);
  doc.text(paragraph1, 20, 80, { maxWidth: 170, align: "justify" });

  const data = props.produto.map((pro) => [
    pro.nomeProduto,
    pro.categoria,
    pro.codigoNota,
    pro.quantidade_saida,
  ]);

  let content = {
    startY: 100,
    head: [["Produto", "Categoria", "Número da Nota", "Quantidade"]],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [203, 203, 203],
    },
  };

  doc.autoTable(content);
  let finalHeight = doc.lastAutoTable.finalY + 20;
  doc.text(paragraph2, 200, finalHeight, {
    maxWidth: 100,
    align: "right",
  });
  doc.line(70, finalHeight + 20, 140, finalHeight + 20);
  doc.text(`${props?.empresa?.nomeEmpresa}`, 105, finalHeight + 25, "center");
  doc.text(paragraph3, 105, finalHeight + 30, "center");

  window.open(doc.output("bloburl"));
};

export const ComprovantePessoa = (props) => {
  let doc = new jsPDF("p", "mm", "a4");

  var imgData = imagePdf();

  doc.addImage(imgData, "png", 20, 10, 170, 45);

  doc.setFont("times", "bold");
  doc.text("RECIBO", 105, 70, "center");

  let paragraph1 = `Eu ${
    props?.people?.nome ? props?.people?.nome : props.pessoa.nome
  }, recebi do Centro de Recuperação Cães e Gatos (CERCEG), conforme Decreto Nº 464/2021, à doação de ração na quantidade abaixo descrita:`;

  let paragraph2;
  if (props.operacao) {
    const dateProps = new Date(props.operacao.createdAt);
    const propsDia = String(dateProps.getDate()).padStart(2, "0");
    const propsMes = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ][dateProps.getMonth()];
    const propsAno = dateProps.getFullYear();
    paragraph2 = `Cornélio Procópio, ${propsDia} de ${propsMes} de ${propsAno}`;
  } else {
    paragraph2 = `Cornélio Procópio, ${dia} de ${mes} de ${ano}.`;
  }
  let paragraph3 = "Assinatura";

  doc.setFont("times", "normal");
  doc.setFontSize(12);
  doc.text(paragraph1, 20, 80, { maxWidth: 170, align: "justify" });

  const data = props.produto.map((pro) => [
    pro.nomeProduto,
    pro.categoria,
    pro.codigoNota,
    pro.quantidade_saida,
  ]);

  let content = {
    startY: 100,
    head: [["Produto", "Categoria", "Número da Nota", "Quantidade"]],
    body: data,
    theme: "grid",
    headStyles: {
      fillColor: [203, 203, 203],
    },
  };

  doc.autoTable(content);
  let finalHeight = doc.lastAutoTable.finalY + 20;
  doc.text(paragraph2, 200, finalHeight, {
    maxWidth: 100,
    align: "right",
  });
  doc.line(70, finalHeight + 20, 140, finalHeight + 20);
  doc.text(`${props?.people?.nome}`, 105, finalHeight + 25, "center");
  doc.text(paragraph3, 105, finalHeight + 30, "center");

  window.open(doc.output("bloburl"));
};

export const DeclaracaoPobreza = (props) => {
  let doc = new jsPDF("p", "mm", "a4");

  var imgData = imagePdf();

  doc.addImage(imgData, "png", 20, 10, 170, 45);

  doc.setFont("times", "bold");
  doc.text("DECLARAÇÃO DE POBREZA", 105, 70, "center");

  let paragraph1 = `       ${props.pessoa.nome}, portador(a) do RG nº ${props.pessoa.rg}, inscrito(a) no CPF nº ${props.pessoa.cpf}, residente e domiciliado(a) na Rua ${props.pessoa.endereco_rua}, nº ${props.pessoa.endereco_numero}, Bairro ${props.pessoa.endereco_bairro}, na cidade de Cornélio Procópio, DECLARA, para os devidos fins, que é pobre na acepção exata do termo, em conformidade com a Lei nº 7.115, de 29 de agosto de 1983, e não possui condições de arcar com os custos vigentes no mercado de alimentos para o(s) seu(s) animal/animais, sem o prejuízo de seu sustento próprio e de sua família.`;

  let paragraph2 = `       Por ser expressão de verdade, firma a presente, sob as penas da lei.`;

  let paragraph3 = `Cornélio Procópio, ${dia} de ${mes} de ${ano}.`;
  let paragraph4 = "Assinatura do Responsável";

  doc.setFont("times", "normal");
  doc.setFontSize(14);
  doc.text(paragraph1, 20, 80, { maxWidth: 170, align: "justify" });
  doc.text(paragraph2, 20, 140, { maxWidth: 170, align: "justify" });
  doc.text(paragraph3, 200, 160, { maxWidth: 100, align: "right" });
  doc.line(70, 180, 140, 180);
  doc.text(paragraph4, 105, 190, "center");

  doc.save(`dec_pobreza_${props.pessoa.nome.replace(" ", "_")}`);
};
