import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import Layout from "../../components/Layout";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { SwalOpen } from "../../components/AlertModal";
import {
  createEmpresa,
  verifyToken,
  getEmpresaDataById,
  deleteContatoEmpresa,
} from "../../sources/api";
import InputMask from "react-input-mask";
import { Formik, FieldArray, ErrorMessage } from "formik";
import * as yup from "yup";
import "./style.css";
import { SolicitacaoFormalEmpresa } from "../../components/PdfGenerator";

export const EmpresaPage = () => {
  const navigate = useNavigate();
  const { authenticated, logout } = useContext(AuthContext);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const removeRef = useRef();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    empresa: {
      nomeEmpresa: "",
      cnpj: "",
      endereco_cep: "",
      endereco_rua: "",
      endereco_numero: "",
      endereco_complemento: "",
      endereco_bairro: "",
      endereco_municipio: "",
      endereco_uf: "",
      tempo_atuacao: "Menos de 01 ano",
      quantidade_resgatados: "Menos de 10 animais",
      quantidade_animais: 0,
      quantidade_cachorros: 0,
      quantidade_gatos: 0,
      quantidade_adocao: 0,
      quantidade_obitos: 0,
      condicao_castrado: false,
      condicao_vacinado: false,
      condicao_desverminado: false,
      condicao_chip: false,
      tempo_permanencia: "No máximo 06 meses",
      local_manutencao: "Na própria residência",
      acompanhamento_pos_adocao: "Não",
      viabilizar_castracao: "Não",
    },
    contatos: [],
  });

  yup.addMethod(yup.string, "checkEmptyString", function () {
    return this.transform((value) => (value === "" ? undefined : value));
  });

  const schema = yup
    .object()
    .shape({
      empresa: yup.object().shape({
        nomeEmpresa: yup.string().required("Nome da empresa é obrigatório"),
        cnpj: yup
          .string()
          .required("CNPJ é obrigatório")
          .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, "CNPJ inválido"),
        endereco_cep: yup
          .string()
          .required("CEP é obrigatório")
          .matches(/([0-9]{5})-([0-9]{3})/, "CEP inválido"),
        endereco_rua: yup.string().required("Rua é obrigatório"),
        endereco_numero: yup.string().required("É necessário um número"),
        endereco_complemento: yup
          .string()
          .required("Complemento é obrigatório"),
        endereco_bairro: yup.string().required("Bairro é obrigatório"),
        endereco_municipio: yup.string().required("Município é obrigatório"),
        endereco_uf: yup.string().required("Estado é obrigatório"),
        tempo_atuacao: yup.string().required("Resposta obrigatória"),
        quantidade_resgatados: yup.string().required("Responta obrigatória"),
        quantidade_animais: yup
          .number()
          .positive("Número deve ser positivo ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
        quantidade_cachorros: yup
          .number()
          .positive("Número deve ser positivo ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
        quantidade_gatos: yup
          .number()
          .positive("Número deve ser positivo ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
        quantidade_adocao: yup
          .number()
          .positive("Número deve ser positivo ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
        quantidade_obitos: yup
          .number()
          .positive("Número deve ser posivito ou 0")
          .integer("Número deve ser inteiro")
          .min(0, "Número deve ser positivo ou 0")
          .required("Campo é obrigatório"),
        condicao_castrado: yup.bool(),
        condicao_vacinado: yup.bool(),
        condicao_desverminado: yup.bool(),
        condicao_chip: yup.bool(),
        tempo_permanencia: yup.string().required("Resposta é obrigatória"),
        acompanhamento_pos_adocao: yup
          .string()
          .required("Resposta é obrigatória"),
        viabilizar_castracao: yup.string().required("Responta é obrigatória"),
      }),
      contatos: yup.array().of(
        yup.object().shape({
          contato_telefone: yup
            .string()
            .required("Insira o número de telefone")
            .matches(
              /^\(([0-9]{2})\)\W([0-9]{5})-([0-9]{4})/,
              "Número inválido."
            ),
          contato_nome: yup.string().required("Insira o nome do contato"),
          contato_tipo: yup.string().required("Insira o tipo do contato"),
          contato_vinculo: yup.string().required("Insira o vínculo do contato"),
        })
      ),
    })
    .required();

  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        if (id) {
          (async () => {
            await setTimeout(
              SwalOpen({
                status: true,
                header: "Buscando dados...",
                message: "Aguarde",
                allowOutsideClick: false,
                timer: 3000,
                type: "info",
              }),
              3000
            );
          })();

          await getEmpresaDataById(id).then(async (response) => {
            console.log(response);
            await setTimeout(function () {
              setInitialValues(response);

              SwalOpen({
                status: true,
                header: "Busca concluida",
                message: "Esta mensagem desaparecerá",
                type: "success",
                timer: 3000,
              });
            }, 2000);
          });
        }
      }
    })();
  }, [reducerValue, id, logout, navigate]);

  const handleDeleteContato = async (idContato, index) => {
    if (idContato) {
      const response = await deleteContatoEmpresa(idContato);
      console.log(response);
      if (response === "success") {
        removeRef.current(index);
      }
    } else {
      removeRef.current(index);
    }
  };

  const handleSubmit = async (data) => {
    console.log("Handle Submit");
    console.log(JSON.stringify(data, null, 2));
    if (id) {
      data.empresa.idEmpresa = id;
    }
    SwalOpen({
      status: true,
      header: "Enviando dados",
      message: "Carregando...",
      style: "info",
      type: "info",
    });
    await createEmpresa(data.empresa, data.contatos)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          SwalOpen({
            status: true,
            header: "Erro",
            message: response.error_message,
            type: "warning",
            style: "error",
            timer: 3000,
          });
          handleGenerateSolicitacaoFormal();
        } else {
          SwalOpen({
            status: true,
            header: "Sucesso",
            message: "Cadastro efetuado com sucesso!",
            type: "success",
            timer: 3000,
          });
          setTimeout(() => {
            navigate(`/editar-empresa/${response.data}`);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.error_message);
        SwalOpen({
          status: true,
          header: "Erro",
          message: "Houve um erro no cadastro, tente novamente!\n",
          style: "error",
          response: error?.response?.data?.error_message,
          type: "error",
          timer: 4000,
        });
      });
    forceUpdate();
  };

  const handleGenerateSolicitacaoFormal = () => {
    SolicitacaoFormalEmpresa(initialValues);
  };

  return (
    <>
      <Layout>
        <div className="form-container">
          <h4>Formulário de Cadastro</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} id="cadastroForm">
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="nomeEmpresa">
                      <Form.Label>Nome Entidade</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="empresa.nomeEmpresa"
                        placeholder="Nome da Entidade/ONG"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.nomeEmpresa}
                      />
                      {touched?.empresa?.nomeEmpresa &&
                      errors?.empresa?.nomeEmpresa ? (
                        <span className="error-block">
                          {errors?.empresa?.nomeEmpresa}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="cnpj">
                      <Form.Label>CNPJ</Form.Label>
                      <InputMask
                        className="form-control form-control-sm"
                        mask={"99.999.999/9999-99"}
                        maskChar={""}
                        size="sm"
                        name="empresa.cnpj"
                        value={values.empresa.cnpj}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        {...(id && { disabled: true })}
                      />
                      {touched?.empresa?.cnpj && errors.empresa?.cnpj ? (
                        <span className="error-block">
                          {errors?.empresa?.cnpj}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs="md-3">
                    <Form.Group controlId="endereco_cep">
                      <Form.Label>CEP</Form.Label>
                      <InputMask
                        className="form-control form-control-sm"
                        mask={"99999-999"}
                        maskChar={""}
                        value={values.empresa.endereco_cep}
                        name="empresa.endereco_cep"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.endereco_cep &&
                      errors?.empresa?.endereco_cep ? (
                        <span className="error-block">
                          {errors.empresa?.endereco_cep}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_rua">
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        type="text"
                        size="sm"
                        value={values.empresa.endereco_rua}
                        name="empresa.endereco_rua"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.endereco_rua &&
                      errors?.empresa?.endereco_rua ? (
                        <span className="error-block">
                          {errors?.empresa?.endereco_rua}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md-2">
                    <Form.Group controlId="endereco_numero">
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.empresa.endereco_numero}
                        name="empresa.endereco_numero"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.endereco_numero &&
                      errors?.empresa?.endereco_numero ? (
                        <span className="error-block">
                          {errors?.empresa?.endereco_numero}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="endereco_complemento">
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.empresa.endereco_complemento}
                        name="empresa.endereco_complemento"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.endereco_complemento &&
                      errors?.empresa?.endereco_complemento ? (
                        <span className="error-block">
                          {errors.empresa?.endereco_complemento}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_bairro">
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.empresa.endereco_bairro}
                        name="empresa.endereco_bairro"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.endereco_bairro &&
                      errors?.empresa?.endereco_bairro ? (
                        <span className="error-block">
                          {errors?.empresa?.endereco_bairro}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_municipio">
                      <Form.Label>Município</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.empresa.endereco_municipio}
                        name="empresa.endereco_municipio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.endereco_municipio &&
                      errors?.empresa?.endereco_municipio ? (
                        <span className="error-block">
                          {errors.empresa?.endereco_municipio}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="endereco_uf">
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        value={values.empresa.endereco_uf}
                        name="empresa.endereco_uf"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.endereco_uf &&
                      errors?.empresa?.endereco_uf ? (
                        <span className="error-block">
                          {errors?.empresa?.endereco_uf}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="tempo_atuacao">
                      <Form.Label>
                        Tempo de atuação na proteção de animais
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        name="empresa.tempo_atuacao"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.tempo_atuacao}
                      >
                        <option>Menos de 01 ano</option>
                        <option>Entre 1 e 2 anos</option>
                        <option>Entre 2 e 5 anos</option>
                        <option>Entre 5 e 10 anos</option>
                        <option>Mais de 10 anos</option>
                      </Form.Select>
                      {touched?.empresa?.tempo_atuacao &&
                      errors?.empresa?.tempo_atuacao ? (
                        <span className="error-block">
                          {errors?.empresa?.tempo_atuacao}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="quantidade_resgatados">
                      <Form.Label>
                        Quantidade de resgates até o momento
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        name="empresa.quantidade_resgatados"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.quantidade_resgatados}
                      >
                        <option>Menos de 10 animais</option>
                        <option>Entre 10 e 20 animais</option>
                        <option>Entre 20 e 50 animais</option>
                        <option>Entre 50 e 100 animais</option>
                        <option>Mais de 100 animais</option>
                      </Form.Select>
                      {touched?.empresa?.quantidade_resgatados &&
                      errors?.empresa?.quantidade_resgatados ? (
                        <span className="error-block">
                          {errors?.empresa?.quantidade_resgatados}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="align-items-end">
                  <Col xs="md">
                    <Form.Group controlId="quantidade_animais">
                      <Form.Label>Quantidade de Animais</Form.Label>
                      <Form.Control
                        size="sm"
                        type="number"
                        value={values.empresa.quantidade_animais}
                        name="empresa.quantidade_animais"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.quantidade_animais &&
                      errors?.empresa?.quantidade_animais ? (
                        <span className="error-block">
                          {errors?.empresa?.quantidade_animais}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="quantidade_cachorros">
                      <Form.Label>Quantidade de Cachorros</Form.Label>
                      <Form.Control
                        size="sm"
                        type="number"
                        value={values.empresa.quantidade_cachorros}
                        name="empresa.quantidade_cachorros"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.quantidade_cachorros &&
                      errors?.empresa?.quantidade_cachorros ? (
                        <span className="error-block">
                          {errors?.empresa?.quantidade_cachorros}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="quantidade_gatos">
                      <Form.Label>Quantidade de Gatos</Form.Label>
                      <Form.Control
                        size="sm"
                        type="number"
                        value={values.empresa.quantidade_gatos}
                        name="empresa.quantidade_gatos"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.empresa?.quantidade_gatos &&
                      errors?.empresa?.quantidade_gatos ? (
                        <span className="error-block">
                          {errors?.empresa?.quantidade_gatos}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="quantidade_adocao">
                      <Form.Label>
                        Quantidade já entregues para adoção
                      </Form.Label>
                      <Form.Control
                        size="sm"
                        type="number"
                        name="empresa.quantidade_adocao"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.quantidade_adocao}
                      />
                      {touched?.empresa?.quantidade_adocao &&
                      errors?.empresa?.quantidade_adocao ? (
                        <span className="error-block">
                          {errors?.empresa?.quantidade_adocao}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="tempo_permanencia">
                      <Form.Label>
                        Tempo médio de permanência até adoção
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        name="empresa.tempo_permanencia"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.tempo_permanencia}
                      >
                        <option>No máximo 06 meses</option>
                        <option>Entre 06 a 01 ano</option>
                        <option>Entre 01 ano a 02 anos</option>
                        <option>Entre 02 anos a 05 anos</option>
                        <option>Mais de 05 anos</option>
                      </Form.Select>
                      {touched?.empresa?.tempo_permanencia &&
                      errors?.empresa?.tempo_permanencia ? (
                        <span className="error-block">
                          {errors?.empresa?.tempo_permanencia}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="local_manutencao">
                      <Form.Label>
                        Local de manutenção dos animais até adoção
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        name="empresa.local_manutencao"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.local_manutencao}
                      >
                        <option>Na própria residência</option>
                        <option>Lares temporários</option>
                        <option>Pet Hotéis</option>
                      </Form.Select>
                      {touched?.empresa?.local_manutencao &&
                      errors?.empresa?.local_manutencao ? (
                        <span className="error-block">
                          {errors?.empresa?.local_manutencao}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="md">
                    <Form.Group>
                      <Form.Label>
                        Condições em que os animais são disponibilizados para
                        adoção
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        onChange={(e) =>
                          setFieldValue(
                            "empresa.condicao_castrado",
                            e.target.checked
                          )
                        }
                        onBlur={handleBlur}
                        name="empresa.condicao_castrado"
                        value={values.empresa.condicao_castrado}
                        label="Castrados"
                        checked={values.empresa.condicao_castrado}
                        id="empresa.condicao_castrado"
                      />
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue(
                            "empresa.condicao_vacinado",
                            e.target.checked
                          );
                        }}
                        onBlur={handleBlur}
                        name="empresa.condicao_vacinado"
                        value={values.empresa.condicao_vacinado}
                        label="Vacinados"
                        checked={values.empresa.condicao_vacinado}
                        id="empresa.condicao_vacinado"
                      />
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue(
                            "empresa.condicao_desverminado",
                            e.target.checked
                          );
                        }}
                        onBlur={handleBlur}
                        name="empresa.condicao_desverminado"
                        value={values.empresa.condicao_desverminado}
                        label="Desverminados"
                        checked={values.empresa.condicao_desverminado}
                        id="empresa.condicao_desverminado"
                      />
                      <Form.Check
                        type="checkbox"
                        onChange={(e) => {
                          setFieldValue(
                            "empresa.condicao_chip",
                            e.target.checked
                          );
                        }}
                        onBlur={handleBlur}
                        name="empresa.condicao_chip"
                        value={values.empresa.condicao_chip}
                        checked={values.empresa.condicao_chip}
                        label="Microchipados ou com alguma marca de Identificação"
                        id="empresa.condicao_chip"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="md">
                    <Form.Group controlId="acompanhamento_pos_adocao">
                      <Form.Label>
                        Realiza acompanhamento pós-adoção?
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        name="empresa.acompanhamento_pos_adocao"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.acompanhamento_pos_adocao}
                      >
                        <option>Sim</option>
                        <option>Não</option>
                      </Form.Select>
                      {touched?.empresa?.acompanhamento_pos_adocao &&
                      errors?.empresa?.acompanhamento_pos_adocao ? (
                        <span className="error-block">
                          {errors?.empresa?.acompanhamento_pos_adocao}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col xs="md">
                    <Form.Group controlId="viabilizar_castracao">
                      <Form.Label>
                        Pretende viabilizar a castração gratuita?
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        name="empresa.viabilizar_castracao"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.empresa.viabilizar_castracao}
                      >
                        <option>Sim</option>
                        <option>Não</option>
                      </Form.Select>
                      {touched?.empresa?.viabilizar_castracao &&
                      errors?.empresa?.viabilizar_castracao ? (
                        <span className="error-block">
                          {errors?.empresa?.viabilizar_castracao}
                        </span>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <h3>Contatos</h3>
                </Row>
                <div id="contacts-append">
                  <FieldArray name="contatos">
                    {({ push, remove, errors }) => (
                      (removeRef.current = remove),
                      (
                        <div>
                          {values.contatos.map((contato, index) => (
                            <div key={index}>
                              <Row className="justify-content-center">
                                <Col xs="md">
                                  <Form.Group controlId="contato_telefone">
                                    <Form.Label>Telefone</Form.Label>
                                    <InputMask
                                      size="sm"
                                      type="text"
                                      className="form-control form-control-sm"
                                      mask={"(99) 99999-9999"}
                                      maskChar={""}
                                      value={
                                        values.contatos[index].contato_telefone
                                      }
                                      name={`contatos[${index}].contato_telefone`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_telefone`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs="md">
                                  <Form.Group controlId="contato_tipo">
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select
                                      size="sm"
                                      className="form-control form-control-sm"
                                      name={`contatos[${index}.contato_tipo]`}
                                      value={
                                        values.contatos[index].contato_tipo
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option>Não informado</option>
                                      <option>Residencial</option>
                                      <option>Comercial</option>
                                      <option>Celular</option>
                                    </Form.Select>
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_tipo`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs="md">
                                  <Form.Group controlId="contato_nome">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                      size="sm"
                                      className="form-control form-control-sm"
                                      name={`contatos[${index}].contato_nome`}
                                      value={
                                        values.contatos[index].contato_nome
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_nome`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col xs="md">
                                  <Form.Group controlId="contato_vinculo">
                                    <Form.Label>Vinculo</Form.Label>
                                    <Form.Control
                                      sm="sm"
                                      type="text"
                                      className="form-control form-control-sm"
                                      name={`contatos[${index}].contato_vinculo`}
                                      value={
                                        values.contatos[index].contato_vinculo
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    <span className="error-block">
                                      <ErrorMessage
                                        name={`contatos.${index}.contato_vinculo`}
                                      />
                                    </span>
                                  </Form.Group>
                                </Col>
                                <Col
                                  xs="md"
                                  className="align-self-end justify-content-around"
                                >
                                  <Button
                                    className="btn btn-sm"
                                    variant="danger"
                                    onClick={() =>
                                      handleDeleteContato(
                                        values?.contatos[index]?.idContato,
                                        index
                                      )
                                    }
                                  >
                                    Apagar
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <Row>
                            <Col
                              xs="md"
                              className="button-wrapper auto justify-content-around"
                              id="buttonAppendContato"
                            >
                              <button
                                className="btn btn-sm btn-warning"
                                type="button"
                                onClick={() =>
                                  push({
                                    contato_telefone: "",
                                    contato_vinculo: "",
                                    contato_nome: "",
                                    contato_tipo: "Não informado",
                                  })
                                }
                              >
                                Adicionar Contato
                              </button>
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
                  </FieldArray>
                </div>
                <Row className="form-bottom justify-content-end">
                  <Col
                    xs="md"
                    className="button-wrapper auto justify-content-around"
                  >
                    {id ? (
                      <>
                        <button
                          className="btn btn-sm btn-secondary"
                          type="button"
                          onClick={handleGenerateSolicitacaoFormal}
                        >
                          Gerar solicitação Formal
                        </button>
                      </>
                    ) : null}

                    <button className="btn btn-sm btn-success" type="submit">
                      Salvar Cadastro
                    </button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        </div>
      </Layout>
      {/* {id && <SolicitacaoFormal />} */}
    </>
  );
};

export default EmpresaPage;
