import { useEffect, useContext, useRef, useState } from "react";
import Layout from "../../components/Layout";
import {
  getAllProdutoData,
  getLotesFromProduto,
  verifyToken,
} from "../../sources/api";
import { AuthContext } from "../../contexts/auth";
import { useNavigate } from "react-router-dom";
import SearchLoteModal from "../../components/SearchLoteModal/SearchLoteModal";
import SearchProdutoModal from "../../components/SearchProdutoModal";
import { Formik } from "formik";
import * as yup from "yup";
import { FaSearch } from "react-icons/fa";
import { Row, Col, InputGroup, Form } from "react-bootstrap";
import Table from "../../components/Table";
import { Media } from "react-data-table-component";
import ModalAlterLote from "./ModalAlterLote";
// import { InputGroup } from 'react-bootstrap/InputGroup';

const EntradaDeProdutoPage = () => {
  const { authenticated, logout } = useContext(AuthContext);

  const [produtoData, setProdutoData] = useState({
    idProduto: 0,
    nomeProduto: "",
  });
  const [lotes, setLotes] = useState({});
  const [showModalSearchProduto, setShowModalSearchProduto] = useState(false);
  const [loteToEdit, setLoteToEdit] = useState({});
  const [showModalAltLote, setShowModalAltLote] = useState(false);
  const [data, setData] = useState();
  const [pending, setPending] = useState(true);
  const formRef = useRef();
  const navigate = useNavigate();

  const paginationComponentOptions = {
    rowsPerPageText: "Lotes por páginas",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  const columns = [
    {
      name: "ID Lote",
      selector: (row) => row.idLote,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "Código Nota",
      selector: (row) => row.codigoNota,
      sortable: true,
    },
    {
      name: "Quantidade em estoque",
      selector: (row) => row.quantidadeEstoque,
      sortable: true,
    },
    {
      name: "Ação",
      button: true,
      cell: (row) => (
        <>
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => selectLoteToEdit(row)}
          >
            Selecionar
          </button>
        </>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      const status = await verifyToken();
      if (status === 401 || status === 403) {
        logout();
        navigate("/login");
      } else {
        (async () => {
          await getAllProdutoData().then(async (response) => {
            console.log(response);
            setData(response.data);
            setPending(false);
          });
        })();
      }
    })();
  }, [logout, navigate]);

  useEffect(() => {
    (async () => {
      await getLotesFromProduto(produtoData.idProduto).then(
        async (response) => {
          console.log(response);
          setLotes(response.data);
        }
      );
    })();
  }, [produtoData]);

  const selectLoteToEdit = (row) => {
    console.log(row);
    setLoteToEdit(row);
    setShowModalAltLote(true);
  };

  const onSelectProdutoHandler = (data) => {
    setProdutoData({
      idProduto: data.idProduto,
      nomeProduto: data.nomeProduto,
    });
    setShowModalSearchProduto(false);
  };

  return (
    <Layout>
      <div className="form-container">
        <h4>Entrada de Produto</h4>
        <>
          <SearchProdutoModal
            title={"Pesquisar Produto"}
            show={showModalSearchProduto}
            hide={() => setShowModalSearchProduto(false)}
            data={data}
            onSelect={(data) => onSelectProdutoHandler(data)}
            type={"entrada-produto"}
          />

          <Row>
            <Form.Control
              type="hidden"
              name="idProduto"
              value={produtoData.idProduto}
              onChange={(e) => console.log(e)}
            />
            <Col xs="md">
              <Form.Group controlId="produto.nomeProduto">
                <Form.Label>Produto</Form.Label>
                <InputGroup size="sm" className="mb-3">
                  <Form.Control
                    type="text"
                    aria-label="Buscar"
                    aria-describedby="button-search-entidade"
                    placeholder="Nenhum produto selecionado"
                    value={produtoData.nomeProduto}
                    onChange={(e) => console.log(e)}
                    name="nomeProduto"
                    disabled
                  />
                  <button
                    className="btn btn-outline-secondary"
                    variante="outline-secondary"
                    id="button-search-entidade"
                    type="button"
                    onClick={() => setShowModalSearchProduto(true)}
                  >
                    <FaSearch /> Buscar
                  </button>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </>
      </div>
      {produtoData.idProduto !== 0 && (
        <div className="form-container">
          <Table
            data={lotes}
            columns={columns}
            pending={false}
            paginationComponentOptions={paginationComponentOptions}
          />
        </div>
      )}
      <ModalAlterLote
        showModalAltLote={showModalAltLote}
        setShowModalAltLote={setShowModalAltLote}
        loteToEdit={loteToEdit}
        setLoteToEdit={setLoteToEdit}
        idProduto={produtoData.idProduto}
        setLotes={setLotes}
      />
    </Layout>
  );
};

export default EntradaDeProdutoPage;
